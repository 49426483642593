import React from "react"
import Layout from "react-drawers"
import Menu from "./Menu/Menu"

const DefaultLayout = ({ children, className, segreteria = false }) => {
  return (
    <Layout className={className}>
      <Layout.Top className="p-3 bg-primary text-white">
        <h2 className="text-center m-0">
          {!segreteria && "Oratorio Brembate - Amministrazione sito web"}
          {segreteria && "Oratorio Brembate - Segreteria online"}
        </h2>
      </Layout.Top>
      <Layout.Left className="p-3 bg-gray5">
        <Menu />
      </Layout.Left>
      {children}
    </Layout>
  )
}

export default DefaultLayout