import React from "react"
import useQueryParam from "magik-react-hooks/useRouterQueryParam"
import { useCMSFiles } from "../../hooks/useCMSFiles"
import DefaultLayout from "../../components/DefaultLayout"
import Layout from "react-drawers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFile,
  faFolder,
  faHome,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons"
import { identity } from "lodash"
import classNames from "classnames"
import { Button } from "reactstrap"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { CreateFolderModal } from "./CreateFolderModal"
import { CreateFileModal } from "./CreateFileModal"
import { ConfirmDeleteModal } from "./ConfirmDeleteModal"

function formatSizeBytes(bytes) {
  const units = ["B", "KB", "MB", "GB", "TB"]
  let i = 0
  while(bytes > 1000) {
    i = i + 1
    bytes = Math.round(bytes / 1000)
  }
  return `${bytes} ${units[i]}`
}

export function CMSFiles({ history }) {
  const [path, setPath] = useQueryParam("path", "")
  const [{ data: files }, { createFile, createFolder, unlink }] =
    useCMSFiles(path)

  const pathParts = path.split("/").filter(identity)

  const [folderModalState, folderModalActions] = useModalTrigger()
  const [fileModalState, fileModalActions] = useModalTrigger()
  const [deleteModalState, deleteModalActions] = useModalTrigger()

  return (
    <DefaultLayout>
      <Layout.Content className="d-flex flex-column">
        <Layout.Master
          top={40}
          bottom={40}
          topOpenOnStart={true}
          bottomOpenOnStart={true}
        >
          <Layout absolute>
            <Layout.Top className="bg-lightgray3 d-flex flex-row justify-content-between align-items-center px-2">
              <h3 className="m-0">Gestione Pagine</h3>
            </Layout.Top>
            <Layout.Content className="bg-lightgray4 overflow-y-auto">
              <div className="m-2">
                <div className="row justify-content-center">
                  <div className="col-10 col-xl-8">
                    <div className="bg-white rounded mb-2 p-2 shadow-sm d-flex flex-row justify-content-start align-items-center">
                      <FontAwesomeIcon
                        icon={faHome}
                        className={classNames("pointer", {
                          "text-primary": pathParts.length === 0,
                        })}
                        onClick={() => {
                          setPath("")
                        }}
                      />
                      {pathParts.map((part, i) => (
                        <React.Fragment key={i}>
                          <span className="mx-2">/</span>
                          <span
                            className={classNames("pointer", {
                              "text-primary": i === pathParts.length - 1,
                            })}
                            onClick={() => {
                              setPath(pathParts.slice(0, i + 1).join("/"))
                            }}
                          >
                            {part}
                          </span>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>

                {files && (
                  <div className="row justify-content-center">
                    <div className="col-10 col-xl-8">
                      <div className="bg-white rounded mb-2 p-2 shadow-sm">
                        {files.map((file) => {
                          if (file.type === "directory") {
                            return (
                              <p
                                key={file.name}
                                className="d-flex flex-row justify-content-start align-items-center border-bottom pointer pb-1 mb-1"
                                onClick={() => {
                                  setPath((path ? path + "/" : "") + file.name)
                                }}
                              >
                                <span
                                  className="d-inline-block"
                                  style={{ width: "2em" }}
                                >
                                  <FontAwesomeIcon icon={faFolder} />
                                </span>
                                <span className="flex-1">{file.name}</span>
                                <span className="mr-5 text-muted">{file.size === 1 ? "1 oggetto" : `${file.size} oggetti`}</span>
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    deleteModalActions.open(file.name)
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                              </p>
                            )
                          }
                          return (
                            <p
                              key={file.name}
                              className="d-flex flex-row justify-content-start align-items-center border-bottom pointer pb-1 mb-1"
                              onClick={() => {
                                if (file.name.endsWith(".json")) {
                                  history.push(
                                    `/cms/edit/page${path ? "/" + path : ""}/${
                                      file.name
                                    }`
                                  )
                                }
                              }}
                            >
                              <span
                                className="d-inline-block"
                                style={{ width: "2em" }}
                              >
                                <FontAwesomeIcon icon={faFile} />
                              </span>
                              <span className="flex-1">{file.name}</span>
                              <span className="mr-5 text-muted">{formatSizeBytes(file.size)}</span>
                              <Button
                                color="danger"
                                size="sm"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  deleteModalActions.open(file.name)
                                }}
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </Button>
                            </p>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )}

                <div className="row justify-content-center">
                  <div className="col-10 col-xl-8">
                    <div className="bg-white rounded mb-2 p-2 shadow-sm text-right">
                      <Button
                        color="success"
                        className="mr-2"
                        size="sm"
                        onClick={() => {
                          folderModalActions.open("dummy")
                        }}
                      >
                        <FontAwesomeIcon icon={faFolder} className="mr-1" />
                        <span>Nuova cartella</span>
                      </Button>
                      <Button
                        color="success"
                        size="sm"
                        onClick={() => {
                          fileModalActions.open("dummy")
                        }}
                      >
                        <FontAwesomeIcon icon={faFile} className="mr-1" />
                        <span>Nuovo file</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {folderModalState.value && (
                <CreateFolderModal
                  isOpen={folderModalState.isOpen}
                  onCancel={folderModalActions.close}
                  onClosed={folderModalActions.onClosed}
                  onConfirm={(name) => {
                    createFolder((path ? path + "/" : "") + name)
                    folderModalActions.close()
                  }}
                />
              )}
              {fileModalState.value && (
                <CreateFileModal
                  isOpen={fileModalState.isOpen}
                  onCancel={fileModalActions.close}
                  onClosed={fileModalActions.onClosed}
                  onConfirm={(name) => {
                    createFile((path ? path + "/" : "") + name)
                    fileModalActions.close()
                  }}
                />
              )}
              {deleteModalState.value && (
                <ConfirmDeleteModal
                  path={deleteModalState.value}
                  isOpen={deleteModalState.isOpen}
                  onCancel={deleteModalActions.close}
                  onClosed={deleteModalActions.onClosed}
                  onConfirm={() => {
                    unlink((path ? path + "/" : "") + deleteModalState.value)
                    deleteModalActions.close()
                  }}
                />
              )}
            </Layout.Content>
          </Layout>
        </Layout.Master>
      </Layout.Content>
    </DefaultLayout>
  )
}
