import React, { useRef, useCallback } from "react"
import { Button } from "reactstrap"

const ImageField = ({ form, field }) => {
  const url = typeof field.value === "string" || !field.value ? field.value : field.value.url
  const hasValue = !!field.value
  const inputRef = useRef(null)

  const current = hasValue
    ? <a href={url} target="_blank" rel="noopener noreferrer">Visualizza</a>
    : <i>Nessuna immagine allegata</i>

  const handleSelectedFile = useCallback(e => {
    if (typeof field.value === "object" && field.value !== null) {
      URL.revokeObjectURL(field.value.url)
    }
    form.setFieldValue(field.name, {
      url: URL.createObjectURL(e.target.files[0]),
      file: e.target.files[0]
    })
  }, [field.name, field.value, form])

  return (
    <div className="d-flex flex-row justify-content-between align-items-center">
      {current}
      <div>
        <Button
          size="sm"
          color="light"
          onClick={() => inputRef.current.click()}
        >
          {hasValue ? "Modifica" : "Inserisci"}
        </Button>
        {hasValue && (
          <Button
            size="sm"
            color="danger"
            className="ml-2"
            onClick={() => form.setFieldValue(field.name, null)}
          >
            {"Elimina"}
          </Button>
        )}
        <input type="file" ref={inputRef} style={{ display: "none" }} onChange={handleSelectedFile} />
      </div>
    </div>
  )
}

export default ImageField