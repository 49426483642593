import React, { useState, useRef, useCallback } from "react"
import { useRunRj } from "react-rocketjump"
import { FotoGalleryDetailRj } from "../../state/fotogallery"
import DefaultLayout from "../../components/DefaultLayout"
import Layout from "react-drawers"
import { Spinner, Button } from "reactstrap"
import uniqueId from "lodash/uniqueId"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faImage, faTrashAlt } from "@fortawesome/free-solid-svg-icons"

const recursiveIterator = (files, func) => {
  if (files.length === 0) {
    return Promise.resolve()
  } else {
    return func(files[0]).then(() => {
      recursiveIterator(files.slice(1), func)
    })
  }
}

const FotoGalleryDetail = ({ match, history }) => {
  const albumId = match.params.id

  const [
    { gallery, pending, addingOperations, removingOperations },
    { addImage, removeImage }
  ] = useRunRj(FotoGalleryDetailRj, [albumId])

  const [addingFiles, setAddingFiles] = useState([])
  const fileInputRef = useRef(null)

  const handleFileUpload = useCallback(e => {
    e.persist()
    console.log(e)
    const nextAddingFiles = [
      ...addingFiles,
      ...[...e.target.files].map(file => ({ id: `img${uniqueId()}`, file }))
    ]
    setAddingFiles(nextAddingFiles)
    e.target.value = ""
  }, [addingFiles])

  const isUploadInProgress = Object.values(addingOperations).some(v => !!v)

  const doUpload = useCallback(() => {
    recursiveIterator(addingFiles, ({ id, file }) => {
      return addImage
        .asPromise(albumId, file, id)
        .then(() => {
          setAddingFiles(current => current.filter(f => f.id !== id))
        })
    })
  }, [addImage, addingFiles, albumId])

  return (
    <DefaultLayout>
      <Layout.Content className="d-flex flex-column">
        <Layout.Master
          top={40}
          bottom={40}
          topOpenOnStart={true}
          bottomOpenOnStart={true}
        >
          <Layout absolute>
            <Layout.Top className="bg-lightgray3 d-flex flex-row justify-content-between align-items-center px-2">
              <h3 className="m-0">{gallery ? gallery.title : ""}</h3>
              {pending && (
                <Spinner size="sm" />
              )}
            </Layout.Top>
            <Layout.Content className="bg-lightgray4 overflow-y-auto">
              <div className="m-2">
                <div className="row justify-content-center">
                  <div className="col-6 bg-white rounded mb-2 p-2 shadow-sm">
                    <h5>Immagini pronte per il caricamento</h5>
                    {addingFiles.length === 0 && (
                      <p><i>Nessuna immagine pronta per il caricamento</i></p>
                    )}
                    {addingFiles.map(({ id, file }) => {
                      const isUploading = !!addingOperations[id]
                      const isQueued = !isUploading
                      const canBeDeleted = !isUploadInProgress
                      return (
                        <p className="mb-1 d-flex flex-row justify-content-between align-items-center" key={id}>
                          <span>
                            <span style={{ width: "30px" }}>
                              {isQueued && <FontAwesomeIcon icon={faImage} />}
                              {isUploading && <Spinner size="sm" />}
                            </span>
                            <span className="mx-2 d-inline-block">{file.name}</span>
                          </span>
                          {canBeDeleted && (
                            <Button
                              size="sm"
                              color="danger"
                              onClick={() => {
                                setAddingFiles(files => files.filter(f => f.id !== id))
                              }}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                          )}
                        </p>
                      )
                    })}
                    <div className="mt-2 text-right">
                      <Button
                        type="button"
                        color="secondary"
                        size="sm"
                        className="mr-2"
                        disabled={isUploadInProgress}
                        onClick={() => fileInputRef.current.click()}
                      >
                        {"Seleziona immagini"}
                      </Button>
                      <Button
                        type="button"
                        color="success"
                        size="sm"
                        disabled={isUploadInProgress || addingFiles.length === 0}
                        onClick={doUpload}
                      >
                        {"Carica"}
                      </Button>
                      <input type="file" accept="image/*" multiple={true} ref={fileInputRef} onChange={handleFileUpload} style={{ display: "none" }} />
                    </div>
                  </div>
                </div>
                <div className="row mt-5">
                  {gallery && gallery.items.map(({ id, thumb }) => (
                    <div key={id} className="col-6 col-md-4 col-lg-3">
                      <div className="bg-white rounded mb-2 p-2 shadow-sm" style={{ opacity: removingOperations[id] ? 0.5 : 1 }}>
                        <img src={thumb} style={{ width: "100%", height: 150, objectFit: "contain" }} alt="" />
                        <div className="text-right mt-1">
                          {!removingOperations[id] && (
                            <Button
                              color="danger"
                              size="sm"
                              disabled={removingOperations[id]}
                              onClick={() => removeImage(id)}
                            >
                              {removingOperations[id] && <Spinner size="sm" className="mr-2" />}
                              {"Elimina"}
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Layout.Content>
          </Layout>
        </Layout.Master>
      </Layout.Content>
    </DefaultLayout>
  )
}

export default FotoGalleryDetail