import classNames from "classnames"
import Markdown from "markdown-to-jsx"
import React, { useCallback, useMemo, useState } from "react"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"

function InlineImage({ src, alt }) {
  return (
    <div style={{ textAlign: "center" }}>
      <img
        style={{ display: "inline", maxHeight: 150, maxWidth: 150 }}
        src={src}
        alt={alt}
      />
    </div>
  )
}

export function MarkdownEditor({ value, onChange }) {
  const [activeTab, setActiveTab] = useState("edit")

  const toggle = useCallback(
    (tab) => {
      if (activeTab !== tab) setActiveTab(tab)
    },
    [activeTab]
  )

  const MarkdownOptions = useMemo(
    () => ({
      overrides: {
        h1: {
          component: "h3",
        },
        h2: {
          component: "h4",
        },
        img: {
          component: InlineImage,
        },
      },
    }),
    []
  )

  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classNames("pointer", { active: activeTab === "edit" })}
            onClick={() => {
              toggle("edit")
            }}
          >
            Modifica
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames("pointer", {
              active: activeTab === "preview",
            })}
            onClick={() => {
              toggle("preview")
            }}
          >
            Anteprima
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="edit">
          <textarea
            rows={5}
            className="form-control form-control-sm"
            type="text"
            value={value}
            onChange={onChange}
            style={{ borderTopStyle: "none" }}
          />
        </TabPane>
        <TabPane tabId="preview">
          <div className="my-2">
            {value && <Markdown options={MarkdownOptions}>{value}</Markdown>}
          </div>
        </TabPane>
      </TabContent>
    </>
  )
}
