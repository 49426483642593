import React, { useCallback, useState } from "react"
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

export function CreateFolderModal({ isOpen, onConfirm, onCancel, onClosed }) {
  const [name, setName] = useState("")

  const handleFormSubmit = useCallback(e => {
    e.preventDefault()
    onConfirm(name)
    return false
  }, [name, onConfirm])

  return (
    <Modal isOpen={isOpen} toggle={onCancel} onClosed={onClosed}>
      <ModalHeader toggle={onCancel}>Nuova cartella</ModalHeader>
      <ModalBody>
        <form onSubmit={handleFormSubmit}>
          <FormGroup>
            <Label>Inserisci il nome per la nuova cartella</Label>
            <Input type="text" value={name} onChange={e => setName(e.target.value)} />
          </FormGroup>
        </form>
      </ModalBody>
      <ModalFooter className="text-right">
        <Button
          color="primary"
          onClick={() => {
            onConfirm(name)
          }}
        >
          Conferma
        </Button>
      </ModalFooter>
    </Modal>
  )
}
