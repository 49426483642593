import React, { useEffect, useCallback } from "react"
import { useRj } from "react-rocketjump"
import { EventRj } from "../../state/events"
import DefaultLayout from "../../components/DefaultLayout"
import Layout from "react-drawers"
import { Spinner, Form, FormGroup, Label, Col, FormText, Button, FormFeedback } from "reactstrap"
import { Formik, Field } from "formik"
import InputField from "../../components/InputField"
import DivisionField from "../../components/DivisionField"
import DateField from "../../components/DateField"
import TimeField from "../../components/TimeField"
import ImageField from "../../components/ImageField"
import FileSetField from "../../components/FileSetField"
import useAlert from "../../components/StreamAlert/useAlert"

const EVENT_DEFAULT = {
  division: null,
  summary: "",
  description: "",
  date: null,
  time: null,
  attached_files: [],
  attached_image: null,
  publish_period_start: null,
  publish_period_end: null
}

const getInitialValue = event => {
  const out = {
    ...EVENT_DEFAULT,
    ...event
  }
  out.description = out.description.replace(/<br>/g, "\n")
  return out
}

async function persistEvent(values, actions) {
  const { id, description, attached_files, attached_image, ...payload } = values
  const sendData = {
    ...payload,
    description: description.replace(/\n/g, "<br>")
  }
  let event
  if (id) {
    event = await actions.update.asPromise(id, sendData)
  } else {
    event = await actions.create.asPromise(sendData)
  }
  if (typeof attached_image === "object") {
    if (attached_image === null) {
      await actions.unsetImage(event.id)
    } else {
      event = await actions.setImage(event.id, attached_image.file)
    }
  }
  for (let i = attached_files.length - 1; i >= 0; i--) {
    const fd = attached_files[i]
    if (fd.generated && !fd.deleted) {
      event = await actions.attachFile(event.id, fd.name, fd.file)
    } else if (!fd.generated && fd.deleted) {
      event = await actions.removeFile(event.id, i)
    }
  }
  return event
}

const EventEditPage = ({ history, match }) => {
  const eventId = match.params.id
  const Alert = useAlert()

  const [
    { data: event, pending: isLoading },
    {
      run: loadEvent,
      clean,
      ...eventActions
    }
  ] = useRj(EventRj)

  useEffect(() => {
    if (eventId) {
      loadEvent(eventId)
      return () => clean()
    }
  }, [clean, eventId, loadEvent])

  const handleSubmit = useCallback((values, actions) => {
    Alert.warn("Salvataggio in corso...")
    persistEvent(values, eventActions)
      .then(() => {
        actions.setSubmitting(false)
        Alert.clear()
        Alert.success("Salvato correttamente")
        history.push("/events")
      })
      .catch(err => {
        actions.setSubmitting(false)
        Alert.clear()
        if (err && err.response && err.response.body && err.response.body.detail) {
          Alert.error(err.response.body.detail)
        }
        else if (err && err.response && err.response.body) {
          console.log({ err })
          actions.setErrors(err.response.body)
        }
        console.log(err)
        Alert.error("Errore nel salvataggio")
      })
  }, [Alert, eventActions, history])

  return (
    <DefaultLayout>
      <Layout.Content className="d-flex flex-column">
        <Layout.Master
          top={40}
          bottom={40}
          topOpenOnStart={true}
          bottomOpenOnStart={true}
        >
          <Layout absolute>
            <Layout.Top className="bg-lightgray3 d-flex flex-row justify-content-between align-items-center px-2">
              <h3 className="m-0">{eventId ? "Modifica" : "Inserisci"} evento</h3>
              {isLoading && (
                <Spinner size="sm" />
              )}
            </Layout.Top>
            <Layout.Content className="d-flex flex-row justify-content-center overflow-auto">
              {(!eventId || event) && (
                <Formik
                  initialValues={getInitialValue(event)}
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                >
                  {({ handleSubmit, isSubmitting, errors }) => (
                    <Form onSubmit={handleSubmit} className="col-10 col-md-8">
                      <div className="pt-2" />
                      <FormGroup row>
                        <Label for="division-control" sm={4}>
                          {"Sezione"}
                        </Label>
                        <Col sm={8}>
                          <Field
                            name="division"
                            component={DivisionField}
                            invalid={!!errors.division}
                          />
                          {errors.division && (
                            <FormFeedback className="text-right">{errors.division[0]}</FormFeedback>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={4}>
                          {"Titolo dell'evento"}
                        </Label>
                        <Col sm={8}>
                          <Field
                            name="summary"
                            component={InputField}
                            invalid={!!errors.summary}
                            maxlength={50}
                          />
                          <FormText className="text-right">Massimo 50 caratteri</FormText>
                          {errors.summary && (
                            <FormFeedback className="text-right">{errors.summary[0]}</FormFeedback>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={4}>
                          {"Descrizione dell'evento"}
                        </Label>
                        <Col sm={8}>
                          <Field
                            name="description"
                            component={InputField}
                            type="textarea"
                            rows={5}
                            maxlength={1000}
                            invalid={!!errors.description}
                          />
                          <FormText className="text-right">Massimo 1000 caratteri</FormText>
                          {errors.description && (
                            <FormFeedback className="text-right">{errors.description[0]}</FormFeedback>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={4}>
                          {"Data di svolgimento"}
                        </Label>
                        <Col sm={8}>
                          <Field
                            name="date"
                            component={DateField}
                            dateFormat="dd/MM/yyyy"
                            invalid={!!errors.date}
                          />
                          {errors.date && (
                            <FormFeedback className="text-right">{errors.date[0]}</FormFeedback>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={4}>
                          {"Orario di svolgimento"}
                        </Label>
                        <Col sm={8}>
                          <Field
                            name="time"
                            component={TimeField}
                            invalid={!!errors.time}
                          />
                          {errors.time && (
                            <FormFeedback className="text-right">{errors.time[0]}</FormFeedback>
                          )}
                        </Col>
                      </FormGroup>
                      <hr />
                      <FormGroup row>
                        <Label sm={4}>
                          {"Immagine rappresentativa"}
                        </Label>
                        <Col sm={8}>
                          <Field
                            name="attached_image"
                            component={ImageField}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={4}>
                          {"File allegati"}
                        </Label>
                        <Col sm={8}>
                          <Field
                            name="attached_files"
                            component={FileSetField}
                          />
                        </Col>
                      </FormGroup>
                      <hr />
                      <FormGroup row>
                        <Label sm={4}>
                          {"In bacheca a partire dal"}
                        </Label>
                        <Col sm={8}>
                          <Field
                            name="publish_period_start"
                            component={DateField}
                            dateFormat="dd/MM/yyyy"
                            invalid={!!errors.publish_period_start}
                          />
                          {errors.publish_period_start && (
                            <FormFeedback className="text-right">{errors.publish_period_start[0]}</FormFeedback>
                          )}
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={4}>
                          {"In bacheca fino al"}
                        </Label>
                        <Col sm={8}>
                          <Field
                            name="publish_period_end"
                            component={DateField}
                            dateFormat="dd/MM/yyyy"
                            invalid={!!errors.publish_period_end}
                          />
                          {errors.publish_period_end && (
                            <FormFeedback className="text-right">{errors.publish_period_end[0]}</FormFeedback>
                          )}
                        </Col>
                      </FormGroup>
                      <div className="d-flex flex-row justify-content-between">
                        <Button
                          color="secondary"
                          type="button"
                          disabled={isSubmitting}
                          onClick={() => history.goBack()}
                        >Annulla</Button>
                        <Button
                          color="success"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting && (
                            <Spinner size="sm" className="mr-2" />
                          )}
                          {"Salva"}
                        </Button>
                      </div>
                      <div className="pt-2" />
                    </Form>
                  )}
                </Formik>
              )}
            </Layout.Content>
          </Layout>
        </Layout.Master>
      </Layout.Content>
    </DefaultLayout>
  )
}

export default EventEditPage