import { BachecaPiece } from "./pieces/bacheca";
import { CantiDomenicaPiece } from "./pieces/cantidomenica";
import { ColorBoxPiece } from "./pieces/colorbox";
import { ImagePiece } from "./pieces/image";
import { RainbowSundaySheetPiece } from "./pieces/rainbowsundaysheet";
import { TextPiece } from "./pieces/text";
import { TitlePiece } from "./pieces/title";
import { VideoPiece } from "./pieces/video";

export const pieces = {
  title: TitlePiece,
  text: TextPiece,
  image: ImagePiece,
  colorbox: ColorBoxPiece,
  video: VideoPiece,
  bacheca: BachecaPiece,
  cantidomenica: CantiDomenicaPiece,
  rainbowsundaysheet: RainbowSundaySheetPiece,
}