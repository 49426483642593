import React from "react"
import DefaultLayout from "../../components/DefaultLayout"

const ComingSoon = () => {
  return (
    <DefaultLayout>
      <p>Coming soon!</p>
    </DefaultLayout>
  )
}

export default ComingSoon