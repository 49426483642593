import React from "react"
import DefaultLayout from "../../components/DefaultLayout"

const HomePage = () => {
  return (
    <DefaultLayout>
      
    </DefaultLayout>
  )
}

export default HomePage