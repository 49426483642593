import request from "superagent"
import { API_URL } from "./constants"

export const loginApi = ({ username, password }) => {
  return request.post(`${API_URL}/token/`)
    .send({ username, password })
    .then(({ body: { token }}) => ({
      accessToken: token
    }))
}

export const meApi = token => {
  return request.get(`${API_URL}/me/`)
    .set("Authorization", `Token ${token}`)
    .then(({ body }) => body)
}