import React, { useCallback, useRef } from "react"

export default function FileField({ field, form, className, ...props }) {
  const inputRef = useRef()

  const handleFileChange = useCallback(
    (e) => {
      form.setFieldValue(field.name, e.target.files[0])
      e.target.value = ""
    },
    [field.name, form]
  )

  return (
    <div
      className={"d-flex flex-row align-items-center " + className}
      {...props}
    >
      <input
        accept="application/pdf"
        type="file"
        ref={inputRef}
        onChange={handleFileChange}
        className="d-none"
      />
      <button
        type="button"
        className="btn btn-sm btn-secondary"
        onClick={() => {
          inputRef.current.click()
        }}
      >
        Seleziona file
      </button>
      {field.value && <span className="ml-2">{field.value.name}</span>}
    </div>
  )
}