import React from "react"
import { useRunRj } from "react-rocketjump"
import { FotoGalleryListRj } from "../../state/fotogallery"
import DefaultLayout from "../../components/DefaultLayout"
import Layout from "react-drawers"
import { Spinner, Button, Form, FormFeedback } from "reactstrap"
import { Formik, Field } from "formik"
import InputField from "../../components/InputField"

const FotoGalleryManager = ({ history }) => {
  const [{ data: galleries, pending: isLoading, updating }, { create, update }] = useRunRj(FotoGalleryListRj)

  return (
    <DefaultLayout>
      <Layout.Content className="d-flex flex-column">
        <Layout.Master
          top={40}
          bottom={40}
          topOpenOnStart={true}
          bottomOpenOnStart={true}
        >
          <Layout absolute>
            <Layout.Top className="bg-lightgray3 d-flex flex-row justify-content-between align-items-center px-2">
              <h3 className="m-0">Gestione FotoGallery</h3>
              {isLoading && (
                <Spinner size="sm" />
              )}
            </Layout.Top>
            <Layout.Content className="overflow-auto bg-lightgray4">
              <div className="m-2">
                <div className="row justify-content-center">
                  <div className="col-10 col-md-8 col-lg-6">
                    <div className="bg-white rounded mb-2 p-2 shadow-sm">
                      <Formik
                        initialValues={{ title: "" }}
                        onSubmit={(values, actions) => {
                          create
                            .onSuccess(() => {
                              actions.resetForm()
                              actions.setSubmitting(false)
                            })
                            .onFailure(() => {
                              actions.setErrors({ title: "Nome non valido o già in uso" })
                              actions.setSubmitting(false)
                            })
                            .run(values)
                        }}
                      >
                        {({ isSubmitting, handleSubmit, errors }) => (
                          <Form onSubmit={handleSubmit}>
                            <Field
                              name="title"
                              component={InputField}
                              className="mb-2"
                              placeholder="Nome del nuovo album"
                              invalid={!!errors.title}
                            />
                            {errors.title && (
                              <FormFeedback className="text-right">{errors.title}</FormFeedback>
                            )}
                            <div className="d-flex flex-row justify-content-end align-items-center">
                              <Button
                                color="success"
                                size="sm"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                {isSubmitting && (
                                  <Spinner size="sm" className="mr-2" />
                                )}
                                {"Crea"}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
                {galleries && galleries.map(gallery => (
                  <div className="row justify-content-center">
                    <div className="col-10 col-md-8 col-lg-6">
                      <div className="bg-white rounded mb-2 p-2 shadow-sm" key={gallery.id}>
                        <p>{gallery.title}</p>
                        <div className="d-flex flex-row justify-content-end align-items-center">
                          <Button
                            color="primary"
                            size="sm"
                            type="button"
                            className="mr-2"
                            onClick={() => history.push(`/galleries/${gallery.id}`)}
                          >
                            {"Immagini"}
                          </Button>
                          {!updating[gallery.id] && gallery.visible && (
                            <Button
                              color="secondary"
                              size="sm"
                              type="button"
                              onClick={() => {
                                update(gallery.id, { visible: false })
                              }}
                            >
                              {"Nascondi"}
                            </Button>
                          )}
                          {!updating[gallery.id] && !gallery.visible && (
                            <Button
                              color="secondary"
                              size="sm"
                              type="button"
                              onClick={() => {
                                update(gallery.id, { visible: true })
                              }}
                            >
                              {"Mostra"}
                            </Button>
                          )}
                          {updating[gallery.id] && (
                            <Spinner size="sm" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Layout.Content>
          </Layout>
        </Layout.Master>
      </Layout.Content>
    </DefaultLayout>
  )
}

export default FotoGalleryManager