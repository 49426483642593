import { rj } from "react-rocketjump"
import request from "superagent"
import { API_URL } from "../constants"

export const FotoGalleryListRj = rj({
  effectCaller: rj.configured(),
  effect: token => () => {
    return request.get(`${API_URL}/fotogallery/`)
      .set("Authorization", `Token ${token}`)
      .query({ ordering: "-id" })
      .then(({ body }) => body)
  },
  mutations: {
    create: rj.mutation.single({
      effect: token => data => {
        return request.post(`${API_URL}/fotogallery/`)
          .set("Authorization", `Token ${token}`)
          .send(data)
          .then(({ body }) => body)
      },
      updater: (state, result) => ({
        ...state,
        data: [
          result,
          ...state.data,
        ]
      })
    }),
    update: rj.mutation.multi(
      id => id,
      {
        effect: token => (id, data) => {
          return request.patch(`${API_URL}/fotogallery/${id}/`)
            .set("Authorization", `Token ${token}`)
            .send(data)
            .then(({ body }) => body)
        },
        updater: (state, result) => ({
          ...state,
          data: state.data.map(item => item.id !== result.id ? item : result)
        })
      }
    )
  },
  computed: {
    'pending': 'isPending',
    'data': 'getData',
    'updating': '@mutation.update.pendings'
  }
})

export const FotoGalleryDetailRj = rj({
  effectCaller: rj.configured(),
  effect: token => id => {
    return request.get(`${API_URL}/fotogallery/${id}/`)
      .set("Authorization", `Token ${token}`)
      .then(({ body }) => body)
  },
  mutations: {
    addImage: rj.mutation.multi(
      (albumId, image, seqNr) => seqNr,
      {
        effect: token => (albumId, image) => {
          return request.post(`${API_URL}/fotogallery-item/`)
            .set("Authorization", `Token ${token}`)
            .field("gallery", albumId)
            .attach("image", image)
            .then(({ body }) => body)
        },
        updater: (state, result) => ({
          ...state,
          data: {
            ...state.data,
            items: [
              ...state.data.items,
              result
            ]
          }
        })
      }
    ),
    removeImage: rj.mutation.multi(
      imageId => imageId,
      {
        effect: token => (imageId) => {
          return request.delete(`${API_URL}/fotogallery-item/${imageId}`)
            .set("Authorization", `Token ${token}`)
            .then(() => imageId)
        },
        updater: (state, result) => ({
          ...state,
          data: {
            ...state.data,
            items: state.data.items.filter(item => item.id !== result)
          }
        })
      }
    ),
  },
  computed: {
    gallery: 'getData',
    pending: 'isPending',
    addingOperations: '@mutation.addImage.pendings',
    removingOperations: '@mutation.removeImage.pendings'
  }
})

