import useModalTrigger from "magik-react-hooks/useModalTrigger"
import React, { useCallback } from "react"
import { Button } from "reactstrap"
import { AssetManagerModal } from "../AssetManagerModal"
import { Piece } from "./piece"

export function ImagePiece({ props, tools }) {
  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    return false
  }, [])

  const [editModalState, editModalActions] = useModalTrigger()

  return (
    <Piece name="Immagine" tools={tools}>
      <form onSubmit={handleSubmit} className="form">
        <div className="form-group row">
          <label className="col-3">URL</label>
          <div className="col-9">
            {props.url && (
              <a href={props.url} rel="noopener noreferrer" target="_blank">
                {props.url}
              </a>
            )}
            {!props.url && <i>Nessuna risorsa selezionata</i>}
          </div>
        </div>
        <div className="text-right">
          <Button
            size="sm"
            onClick={() => {
              editModalActions.open("dummy")
            }}
          >
            Modifica
          </Button>
        </div>
      </form>
      {editModalState.value && (
        <AssetManagerModal
          isOpen={editModalState.isOpen}
          onCancel={editModalActions.close}
          onClosed={editModalActions.onClosed}
          onConfirm={(result) => {
            tools.setProp("url", result)
            editModalActions.close()
          }}
        />
      )}
    </Piece>
  )
}

ImagePiece.cmsName = "Immagine"
