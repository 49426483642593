import React from "react";

export default function InputField({ field, form, className, ...props }) {
  return (
    <input
      className={"form-control form-control-sm " + className}
      {...field}
      {...props}
    />
  );
}
