import { faHome } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { identity } from "lodash"
import React, { useCallback } from "react"
import { MarkdownEditor } from "../../../components/MarkdownEditor"
import { Piece } from "./piece"

function FilePath({ path }) {
  if (path === "/") {
    return <FontAwesomeIcon icon={faHome} />
  }
  const parts = path.split("/").filter(identity)
  return (
    <>
      <FontAwesomeIcon icon={faHome} />
      {parts.map((part, i) => (
        <React.Fragment key={i}>
          <span className="mx-2">/</span>
          <span>{part}</span>
        </React.Fragment>
      ))}
    </>
  )
}

export function TextPiece({ props, tools }) {
  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    return false
  }, [])

  return (
    <Piece name="Testo" tools={tools}>
      <form onSubmit={handleSubmit} className="form">
        <div className="form-group row">
          <label className="col-3">Sorgente</label>
          <div className="col-9">
            {props.file && <FilePath path={props.file} />}
            {!props.file && <i>Contenuto diretto</i>}
          </div>
        </div>
        {!props.file && (
          <div className="form-group row">
            <label className="col-3">Contenuto</label>
            <div className="col-9">
              <MarkdownEditor
                value={props.text}
                onChange={(e) => {
                  tools.setProp("text", e.target.value)
                }}
              />
            </div>
          </div>
        )}
      </form>
    </Piece>
  )
}

TextPiece.initialProps = {
  text: "",
}
TextPiece.cmsName = "Testo"
