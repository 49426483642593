import React from "react"
import Layout from "react-drawers"
import { ConfigureRj } from "react-rocketjump"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import Auth, { useAuthActions } from "use-eazy-auth"
import { AuthRoute, GuestRoute } from "use-eazy-auth/routes"
import { loginApi, meApi } from "./auth"
import StreamAlert from "./components/StreamAlert/StreamAlert"
import { BASENAME } from "./constants"
import Reservations from "./office/Reservations"
import AddConfCanti from "./pages/AddConfCanti"
import Canti from "./pages/Canti"
import { CMSEditorPage } from "./pages/CMSEditorPage"
import { CMSFiles } from "./pages/CMSFiles"
import ComingSoon from "./pages/ComingSoon"
import EditConfCanti from "./pages/EditConfCanti"
import EventEditPage from "./pages/EventEditPage/EventEditPage"
import EventsPage from "./pages/EventsPage"
import FotoGalleryDetail from "./pages/FotoGalleryDetail"
import FotoGalleryManager from "./pages/FotoGalleryManager"
import HomePage from "./pages/HomePage"
import LoginPage from "./pages/LoginPage"
import { NostraComunita } from "./pages/NostraComunita"
import SundayUploadPage from "./pages/SundayUploadPage"
import VisitsPage from "./pages/VisitsPage"

function InjectAuth({ children }) {
  const { callAuthApiPromise } = useAuthActions()

  return <ConfigureRj effectCaller={callAuthApiPromise}>{children}</ConfigureRj>
}

function App() {
  return (
    <Auth loginCall={loginApi} meCall={meApi}>
      <InjectAuth>
        <Layout.Master
          mode="TLRB"
          top={61}
          left={250}
          bottom={55}
          topOpenOnStart={true}
          leftOpenOnStart={true}
          bottomOpenOnStart={true}
        >
          <StreamAlert>
            <Router basename={BASENAME}>
              <Switch>
                <GuestRoute exact path="/login" component={LoginPage} />
                <AuthRoute exact path="/" component={HomePage} />
                <AuthRoute exact path="/events" component={EventsPage} />
                <AuthRoute
                  exact
                  path="/events/sunday"
                  component={SundayUploadPage}
                />
                <AuthRoute exact path="/events/new" component={EventEditPage} />
                <AuthRoute exact path="/events/:id" component={EventEditPage} />
                <AuthRoute
                  exact
                  path="/galleries"
                  component={FotoGalleryManager}
                />
                <AuthRoute
                  exact
                  path="/galleries/:id"
                  component={FotoGalleryDetail}
                />
                <AuthRoute exact path="/visits" component={VisitsPage} />
                <AuthRoute exact path="/visits/:date" component={VisitsPage} />
                <AuthRoute exact path="/canti" component={Canti} />
                <AuthRoute exact path="/canti/new" component={AddConfCanti} />
                <AuthRoute exact path="/canti/:id" component={EditConfCanti} />
                <AuthRoute exact path="/cms" component={CMSFiles} />
                <AuthRoute
                  exact
                  path="/cms/edit/page/:path+"
                  component={CMSEditorPage}
                />
                <AuthRoute
                  exact
                  path="/nostracomunita"
                  component={NostraComunita}
                />

                <AuthRoute
                  exact
                  path="/reservations"
                  component={Reservations}
                />
                <AuthRoute component={ComingSoon} />
              </Switch>
            </Router>
          </StreamAlert>
        </Layout.Master>
      </InjectAuth>
    </Auth>
  )
}

export default App
