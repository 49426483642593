import React, { useCallback, useRef } from "react"
import { Button } from "reactstrap"

const FileSetField = ({ form, field }) => {
  const inputRef = useRef(null)

  const onFileSelected = useCallback(e => {
    const file = e.target.files[0]
    const url = URL.createObjectURL(file)
    const name = file.name
    form.setFieldValue(field.name, [
      ...field.value,
      {
        name, 
        url,
        file,
        generated: true
      }
    ])
    e.target.value = ""
  }, [field.name, field.value, form])

  return (
    <div>
      {field.value.map((f, i) => {
        if (f.deleted) {
          return null
        } else {
          return (
            <p key={i} className="d-flex flex-row justify-content-between align-items-center">
              <a href={f.url} target="_blank" rel="noopener noreferrer">{f.name}</a>
              <Button
                color="danger"
                type="button"
                size="sm"
                onClick={() => {
                  const nextFiles = [...field.value]
                  nextFiles[i].deleted = true
                  if (nextFiles[i].generated) {
                    URL.revokeObjectURL(nextFiles[i].url)
                  }
                  form.setFieldValue(field.name, nextFiles)
                }}
              >
                {"Elimina"}
              </Button>
            </p>
          )
        }
      })}
      <Button
        type="button"
        color="light"
        size="sm"
        onClick={() => inputRef.current.click()}
      >
        {"Aggiungi"}
      </Button>
      <input ref={inputRef} type="file" onChange={onFileSelected} style={{ display: "none" }} />
    </div>
  )
}

export default FileSetField