import React from "react"
import { useRunRj, useRj } from "react-rocketjump"
import { VisitsRj, VisitSnapshotsRj, VisitSaveSnapshotRj } from "../../state/visits"
import DefaultLayout from "../../components/DefaultLayout"
import Layout from "react-drawers"
import { Spinner, Button } from "reactstrap"
import dayjs from "dayjs"
import useAlert from "../../components/StreamAlert/useAlert"

const VisitsPage = ({ history, match }) => {
  const Alert = useAlert()
  const [{ data: visits, pending: visitsLoading }] = useRunRj(VisitsRj, [match.params.date])
  const [{ data: snapshots, pending: snapshotsLoading }, { run: loadSnapshots }] = useRunRj(VisitSnapshotsRj, [])
  const [, { run: saveSnapshot }] = useRj(VisitSaveSnapshotRj)

  const isLoading = visitsLoading || snapshotsLoading

  return (
    <DefaultLayout>
      <Layout.Content className="d-flex flex-column">
        <Layout.Master
          top={40}
          bottom={40}
          topOpenOnStart={true}
          bottomOpenOnStart={true}
        >
          <Layout absolute>
            <Layout.Top className="bg-lightgray3 d-flex flex-row justify-content-between align-items-center px-2">
              <h3 className="m-0">Conteggio visitatori</h3>
              {isLoading && (
                <Spinner size="sm" />
              )}
            </Layout.Top>
            <Layout.Content className="bg-lightgray4 overflow-y-auto">
              <div className="m-2">
                <div className="row justify-content-center">
                  <div className="col-10 col-md-8 col-lg-6">
                    <form className="form form-inline bg-white rounded mb-2 p-2 shadow-sm d-flex flex-row justify-content-between align-items-center">
                      <span>Seleziona data per visualizzare i conteggi</span>
                      {snapshots && (
                        <select
                          className="form-control form-control-sm"
                          value={match.params.date || "now"}
                          onChange={e => {
                            if (e.target.value === "now") {
                              history.push("/visits")
                            } else {
                              history.push(`/visits/${e.target.value}`)
                            }
                          }}
                        >
                          <option value="now">Attuale</option>
                          {match.params.date && snapshots.indexOf(match.params.date) === -1 && (
                            <option value={match.params.date}>{dayjs(match.params.date).format("DD/MM/YYYY")}</option>
                          )}
                          {snapshots.slice(0).reverse().map(datestr => (
                            <option key={datestr} value={datestr}>{dayjs(datestr).format("DD/MM/YYYY")}</option>
                          ))}
                        </select>
                      )}
                      {!snapshots && (
                        <Spinner size="sm" />
                      )}
                    </form>
                    {!match.params.date && (
                      <div className="bg-white rounded mb-2 p-2 shadow-sm d-flex flex-row justify-content-between align-items-center">
                        <span>Salva conteggi</span>
                        <Button
                          size="sm"
                          color="success"
                          onClick={() => {
                            saveSnapshot
                              .onSuccess(() => {
                                Alert.success("Snapshot salvato correttamente")
                                loadSnapshots()
                              })
                              .run()
                          }}
                        >
                          {"Salva"}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>

                {visits && (
                  <div className="row justify-content-center">
                    <div className="col-10 col-md-8 col-lg-6">
                      <div className="bg-white rounded mb-2 p-2 shadow-sm">
                        <h4 className="mb-3">Generale</h4>
                        {Object.keys(visits.main).sort().map(page => {
                          return (
                            <p className="d-flex flex-row justify-content-between align-items-center border-bottom">
                              <span>{page}</span>
                              <span>{visits.main[page]}</span>
                            </p>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )}

                {visits && Object.keys(visits).filter(section => section !== "main").sort().map(section => {
                  return (
                    <div className="row justify-content-center">
                      <div className="col-10 col-md-8 col-lg-6">
                        <div className="bg-white rounded mb-2 p-2 shadow-sm">
                          <h4 className="mb-3">{section}</h4>
                          {Object.keys(visits[section]).sort().map(page => {
                            return (
                              <p className="d-flex flex-row justify-content-between align-items-center border-bottom">
                                <span>{page}</span>
                                <span>{visits[section][page]}</span>
                              </p>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  )
                })
                }
              </div>
            </Layout.Content>
          </Layout>
        </Layout.Master>
      </Layout.Content>
    </DefaultLayout>
  )
}

export default VisitsPage