import React, { useCallback } from "react"
import { Piece } from "./piece"

export function ColorBoxPiece({ props, tools }) {
  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    return false
  }, [])

  return (
    <Piece name="Avviso colorato" tools={tools}>
      <form onSubmit={handleSubmit} className="form">
        <div className="form-group row">
          <label className="col-3">Titolo</label>
          <div className="col-9">
            <input
              className="form-control form-control-sm"
              type="text"
              value={props.title ?? ""}
              onChange={(e) => {
                tools.setProp("title", e.target.value)
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3">Messaggio</label>
          <div className="col-9">
            <textarea
              className="form-control form-control-sm"
              type="text"
              value={props.message ?? ""}
              onChange={(e) => {
                tools.setProp("message", e.target.value)
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3">Link</label>
          <div className="col-9">
            <input
              className="form-control form-control-sm"
              type="url"
              value={props.link ?? ""}
              onChange={(e) => {
                tools.setProp("link", e.target.value)
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3">Testo del link</label>
          <div className="col-9">
            <input
              className="form-control form-control-sm"
              type="text"
              value={props.linkCaption ?? ""}
              onChange={(e) => {
                tools.setProp("linkCaption", e.target.value)
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3">Colore</label>
          <div className="col-9">
            <input
              className="form-control form-control-sm"
              style={{ width: 60 }}
              type="color"
              value={props.color ?? ""}
              onChange={(e) => {
                tools.setProp("color", e.target.value)
              }}
            />
          </div>
        </div>
      </form>
    </Piece>
  )
}

ColorBoxPiece.initialProps = {
  title: "",
  message: "",
  link: "",
  linkCaption: "",
  color: "#ff8300",
}

ColorBoxPiece.cmsName = "Messaggio colorato"