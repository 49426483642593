import React, { useState, useCallback, useMemo } from "react"
import dayjs from "dayjs"
import { useRunRj } from "react-rocketjump"
import { ReservationsRj } from "../../state/reservations"
import DefaultLayout from "../../components/DefaultLayout"
import Layout from "react-drawers"
import { Spinner, Button } from "reactstrap"
import Calendar from "./Calendar"
import "./Reservations.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEuroSign } from "@fortawesome/free-solid-svg-icons"
import EventModal from "./EventModal"

const Reservations = () => {
  const [[month, year], setMonthAndYear] = useState([dayjs().get("month"), dayjs().get("year")])
  const [[viewMode, viewEvent], setViewEvent] = useState(["?", null])
  const [selectedDate, setSelectedDate] = useState(null)
  const [hoverDate, setHoverDate] = useState(null)

  const eventsFilter = useMemo(() => ({
    data__gte: dayjs(new Date(year, month, 15)).startOf("month").format("YYYY-MM-DD"),
    data__lte: dayjs(new Date(year, month, 15)).endOf("month").format("YYYY-MM-DD")
  }), [month, year])

  const [
    { data: events, pending: areEventsLoading },
    { create, update, remove }
  ] = useRunRj(ReservationsRj, [eventsFilter])
  const today = dayjs().format("YYYY-MM-DD")

  const goToNextMonth = useCallback(() => {
    const nextMonth = (month + 1) % 12
    const nextYear = nextMonth === 0 ? year + 1 : year
    setMonthAndYear([nextMonth, nextYear])
    setSelectedDate(null)
  }, [month, year])

  const goToPrevMonth = useCallback(() => {
    const nextMonth = (month + 11) % 12
    const nextYear = nextMonth === 11 ? year - 1 : year
    setMonthAndYear([nextMonth, nextYear])
    setSelectedDate(null)
  }, [month, year])

  const showEventsForDate = selectedDate || hoverDate || today
  const showEvents = (events || [])
    .filter(e => e.data === showEventsForDate)

  return (
    <DefaultLayout segreteria>
      <Layout.Content className="d-flex flex-column">
        <Layout.Master
          top={40}
          bottom={40}
          topOpenOnStart={true}
          bottomOpenOnStart={true}
        >
          <Layout absolute>
            <Layout.Top className="bg-lightgray3 d-flex flex-row justify-content-between align-items-center px-2">
              <h3 className="m-0">Prenotazione spazi</h3>
              {areEventsLoading && (
                <Spinner size="sm" />
              )}
            </Layout.Top>
            <Layout.Content className="overflow-auto">
              <div className="d-flex flex-row justify-content-center align-items-start mt-5">
                <Calendar
                  month={month}
                  year={year}
                  goToNextMonth={goToNextMonth}
                  goToPrevMonth={goToPrevMonth}
                  onDateHover={setHoverDate}
                  onDateSelect={date => {
                    if (date === selectedDate) {
                      setSelectedDate(null)
                    } else {
                      setSelectedDate(date)
                    }
                  }}
                  selectedDate={selectedDate}
                  highlightDates={events && events.map(evt => evt.data)}
                />
                <div className="event-holder ml-5">
                  <p className="header">Eventi in programma il {dayjs(showEventsForDate).format("DD/MM/YYYY")}</p>
                  {showEvents.map(evt => (
                    <div
                      className="event-row"
                      onClick={() => setViewEvent(["read", evt])}
                    >
                      <div className="event-data">
                        <div>
                          <small>{evt.evento}</small>
                        </div>
                        <div>
                          {evt.referente}
                        </div>
                      </div>
                      <div className="event-paid">
                        {!evt.pagato && (
                          <span className="text-danger">
                            <FontAwesomeIcon icon={faEuroSign} />
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                  {showEvents.length === 0 && (
                    <div>
                      <i>Nessun evento!</i>
                    </div>
                  )}
                  <Button
                    block
                    className="mt-3"
                    type="button"
                    size="sm"
                    color="light"
                    onClick={() => setViewEvent(["create", null])}
                  >
                    {"Aggiungi prenotazione"}
                  </Button>
                </div>
              </div>

              {(viewEvent !== null || viewMode === "create") && (
                <EventModal
                  mode={viewMode}
                  selectedDate={showEventsForDate}
                  changeMode={mode => setViewEvent([mode, viewEvent])}
                  event={viewEvent}
                  toggle={() => setViewEvent(["?", null])}
                  updateEvent={evt => update.asPromise(evt.id, evt)}
                  createEvent={evt => {
                    const d = dayjs(evt.data)
                    const from = dayjs(new Date(year, month, 15)).startOf("month").subtract(1, "second")
                    const to = dayjs(new Date(year, month, 15)).endOf("month").add(1, "second")
                    if (d.isAfter(from, "second") && d.isBefore(to, "second")) {
                      return create.asPromise(evt, true)
                    } else {
                      return create.asPromise(evt, false)
                    }
                  }}
                  deleteEvent={evt => remove.asPromise(evt.id)}
                />
              )}

            </Layout.Content>
          </Layout>
        </Layout.Master>
      </Layout.Content>
    </DefaultLayout>
  )
}

export default Reservations