import {
  faArrowDown,
  faArrowUp,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React from "react"
import { Button } from "reactstrap"

export function Piece({ name, children, tools }) {
  return (
    <div className="row justify-content-center mb-3">
      <div className="col-10 col-xl-8">
        <div className="bg-white rounded p-3 shadow-sm">
          <div
            className={classNames(
              "d-flex flex-row justify-content-start align-items-center",
              {
                "mb-3 pb-1 border-bottom": children,
                "mb-0": !children,
              }
            )}
          >
            <h4 className="m-0 flex-1">{name}</h4>
            {tools?.moveUp && (
              <Button
                size="sm"
                className="mr-2"
                onClick={() => {
                  tools.moveUp()
                }}
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </Button>
            )}
            {tools?.moveDown && (
              <Button
                size="sm"
                className="mr-4"
                onClick={() => {
                  tools.moveDown()
                }}
              >
                <FontAwesomeIcon icon={faArrowDown} />
              </Button>
            )}
            {tools?.remove && (
              <Button
                color="danger"
                size="sm"
                onClick={() => {
                  tools.remove()
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            )}
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  )
}
