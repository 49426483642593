import React, { useRef } from "react"
import Layout from "react-drawers"
import DefaultLayout from "../../components/DefaultLayout"
import { Button, Form, FormGroup, Input, Label, Table } from "reactstrap"
import { useNostraComunitaReleases } from "../../hooks/useNostraComunitaReleases"
import { useFormik } from "formik"

const FORM_INITIAL = {
  year: "",
  seq: "",
  title: "",
  file: null,
}

export function NostraComunita() {
  const [{ data: releases }, { upload }] = useNostraComunitaReleases()

  const formik = useFormik({
    initialValues: FORM_INITIAL,
    onSubmit: (values, actions) => {
      const fd = new FormData()
      fd.append("title", values.title)
      fd.append("seq", values.seq)
      fd.append("year", values.year)
      fd.append("file", values.file)
      return upload.asPromise(fd)
    },
  })

  const fileInputRef = useRef()

  return (
    <DefaultLayout>
      <Layout.Content className="d-flex flex-column">
        <Layout.Master
          top={40}
          bottom={40}
          topOpenOnStart={true}
          bottomOpenOnStart={true}
        >
          <Layout absolute>
            <Layout.Top className="bg-lightgray3 d-flex flex-row justify-content-between align-items-center px-2">
              <h3 className="m-0">{'Uscite de "La Nostra Comunità"'}</h3>
            </Layout.Top>
            <Layout.Content className="bg-light overflow-auto py-4">
              <div className="row justify-content-center">
                <div className="col-10 col-xl-8">
                  <div className="bg-white rounded mb-2 p-2 shadow-sm">
                    <Form onSubmit={formik.handleSubmit}>
                      <h2>Pubblica nuovo numero</h2>
                      <div className="row">
                        <div className="col">
                          <FormGroup>
                            <Label className="m-0">Anno</Label>
                            <Input
                              type="number"
                              step="1"
                              value={formik.values.year}
                              onChange={formik.handleChange}
                              name="year"
                            />
                          </FormGroup>
                        </div>
                        <div className="col">
                          <FormGroup>
                            <Label className="m-0">Numero progressivo</Label>
                            <Input
                              type="number"
                              step="1"
                              value={formik.values.seq}
                              onChange={formik.handleChange}
                              name="seq"
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <FormGroup>
                        <Label className="m-0">Titolo</Label>
                        <Input
                          type="text"
                          value={formik.values.title}
                          onChange={formik.handleChange}
                          name="title"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="m-0">File</Label>
                        <Input
                          type="file"
                          className="d-none"
                          innerRef={fileInputRef}
                          onChange={(e) =>
                            formik.setFieldValue("file", e.target.files[0])
                          }
                        />
                        <Button
                          color="light"
                          className="d-block w-100 text-left"
                          style={{
                            textOverflow: "ellipses",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                          onClick={() => fileInputRef.current.click()}
                        >
                          {formik.values.file && (
                            <span>{formik.values.file.name}</span>
                          )}
                          {!formik.values.file && <i>Seleziona file</i>}
                        </Button>
                      </FormGroup>
                      <div className="text-right">
                        <Button
                          color="success"
                          disabled={
                            !formik.values.file ||
                            !formik.values.year ||
                            !formik.values.seq ||
                            !formik.values.title
                          }
                        >
                          Pubblica
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-10 col-xl-8">
                  <div className="bg-white rounded mb-2 p-2 shadow-sm">
                    {releases && (
                      <Table>
                        <thead>
                          <tr>
                            <th>Anno</th>
                            <th>Numero</th>
                            <th>Titolo</th>
                          </tr>
                        </thead>
                        <tbody>
                          {releases.map((release) => (
                            <tr key={`${release.year}-${release.seq}`}>
                              <td>{release.year}</td>
                              <td>{release.seq}</td>
                              <td>{release.title}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </div>
              </div>
            </Layout.Content>
          </Layout>
        </Layout.Master>
      </Layout.Content>
    </DefaultLayout>
  )
}
