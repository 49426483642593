import React, { useCallback } from "react"
import { InputGroup, Input, InputGroupAddon, InputGroupText, Button } from "reactstrap"

const getComponent = (time, place) => {
  if (!time) {
    return ""
  }
  const part = time.split(":")[place]
  if (!part) {
    return ""
  }
  return part
}

const TimeField = ({ form, field, invalid, ...others }) => {
  const hour = getComponent(field.value, 0)
  const minute = getComponent(field.value, 1)

  const setHour = useCallback(hour => {
    const current = field.value
    if (hour !== "" && isNaN(parseInt(hour, 10))) {
      return
    }
    const hourToSet = hour ? parseInt(hour, 10).toString().padStart(2, "0") : hour
    if (!current) {
      form.setFieldValue(field.name, `${hourToSet}:00:00`)
    } else {
      const [, minute, second] = current.split(':')
      form.setFieldValue(field.name, [hourToSet, minute, second].join(":"))
    }
  }, [field.name, field.value, form])

  const setMinute = useCallback(minute => {
    const current = field.value
    if (minute !== "" && isNaN(parseInt(minute, 10))) {
      return 
    }
    const minuteToSet = minute ? parseInt(minute, 10).toString().padStart(2, "0") : minute
    if (!current) {
      form.setFieldValue(field.name, `00:${minuteToSet}:00`)
    } else {
      const [hour,, second] = current.split(':')
      form.setFieldValue(field.name, [hour, minuteToSet, second].join(":"))
    }
  }, [field.name, field.value, form])

  return (
    <InputGroup>
      <Input value={hour} invalid={invalid} onChange={e => setHour(e.target.value)} />
      <InputGroupAddon className="rounded-0">
        <InputGroupText className="rounded-0">:</InputGroupText>
      </InputGroupAddon>
      <Input value={minute} invalid={invalid} onChange={e => setMinute(e.target.value)} />
      <InputGroupAddon addonType="append">
        <Button color="secondary" type="button" onClick={() => form.setFieldValue(field.name, null)}>Cancella</Button>
      </InputGroupAddon>
    </InputGroup>
  )
}

export default TimeField