import React from "react"

export function AddItemHandle({ onClick }) {
  return (
    <div className="row justify-content-center mb-3">
      <div className="col-10 col-xl-8">
        <div className="text-center d-flex flex-row justify-content-center">
          <div className="pointer" onClick={onClick}>
            <i>Aggiungi elemento</i>
          </div>
        </div>
      </div>
    </div>
  )
}
