import React from "react"
import { useHistory } from "react-router-dom"
import "./Menu.scss"
import { useAuthActions } from "use-eazy-auth"

const MenuEntry = ({ caption, url, onClick }) => {
  const history = useHistory()

  return (
    <li onClick={() => onClick ? onClick() : history.push(url)}>
      {caption}
    </li>
  )
}

const Menu = () => {
  const { logout } = useAuthActions()

  return (
    <>
      <p className="mb-0 mt-2">
        <b>Gestione sito</b>
      </p>
      <ul className="menu">
        <MenuEntry
          caption="Gestione eventi"
          url="/events"
        />
        <MenuEntry
          caption="Crea nuovo evento"
          url="/events/new"
        />
        <MenuEntry
          caption="Pubblica foglio degli avvisi"
          url="/events/sunday"
        />
        <MenuEntry
          caption="Pubblica notiziario"
          url="/nostracomunita"
        />
      </ul>
      <ul className="menu">
        <MenuEntry
          caption="Gestione Pagine"
          url="/cms"
        />
      </ul>
      <ul className="menu">
        <MenuEntry
          caption="Gestione FotoGallery"
          url="/galleries"
        />
      </ul>
      <ul className="menu">
        <MenuEntry
          caption="Conteggio visitatori"
          url="/visits"
        />
      </ul>
      <ul className="menu">
        <MenuEntry
          caption="Pubblicazione canti"
          url="/canti"
        />
      </ul>
      <p className="mb-0 mt-2">
        <b>Segreteria online</b>
      </p>
      <ul className="menu">
        <MenuEntry
          caption="Prenotazioni"
          url="/reservations"
        />
      </ul>
      <p className="mb-0 mt-2">
        <b>Altre funzioni</b>
      </p>
      <ul className="menu">
        <MenuEntry
          caption="Logout"
          onClick={() => {
            logout()
          }}
        />
      </ul>
    </>
  )
}

export default Menu