import { rj } from "react-rocketjump"
import API from "../lib/api"

const SongsState = rj({
  effectCaller: rj.configured(),
  effect: (token) => () => {
    return API.auth(token).get("/songs/").toPromise()
  },
})

export default SongsState
