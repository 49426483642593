import { rj } from "react-rocketjump"
import request from "superagent"
import { API_URL } from "../constants"

export const ReservationsRj = rj({
  effectCaller: rj.configured(),
  effect: token => (filters = {}) => {
    return request
      .get(`${API_URL}/reservations/`)
      .set("Authorization", `Token ${token}`)
      .query(filters)
      .then(({ body }) => body)
  },
  mutations: {
    update: {
      effect: token => (id, data) => {
        return request
          .patch(`${API_URL}/reservations/${id}/`)
          .set("Authorization", `Token ${token}`)
          .send(data)
          .then(({ body }) => body)
      },
      updater: (state, result) => {
        return {
          ...state,
          data: state.data.map(item => item.id !== result.id ? item : result)
        }
      }
    },
    remove: {
      effect: token => id => {
        return request
          .delete(`${API_URL}/reservations/${id}/`)
          .set("Authorization", `Token ${token}`)
          .then(() => id)
      },
      updater: (state, id) => ({
        ...state,
        data: state.data.filter(item => item.id !== id)
      })
    },
    create: {
      effect: token => (data, append=true) => {
        return request
          .post(`${API_URL}/reservations/`)
          .set("Authorization", `Token ${token}`)
          .send(data)
          .then(({ body }) => ({ append, result: body }))
      },
      updater: (state, { append, result }) => {
        if (append) {
          return {
            ...state,
            data: [...state.data, result]
          }
        } else {
          return state
        }
      }
    }
  }
})
