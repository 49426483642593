import React from "react"

export default function ChoiceField({ field, form, className, options }) {
  return (
    <select className={"form-control form-control-sm " + className} {...field}>
      {options.map((opt) => (
        <option value={opt.value} key={opt.value}>{opt.label}</option>
      ))}
    </select>
  )
}