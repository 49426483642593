/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useCallback, useState } from "react"
import useQueryParam from "magik-react-hooks/useRouterQueryParam"
import { qpInt } from "magik-react-hooks/qpUtils"
import { useRunRj } from "react-rocketjump"
import { EventsListRj } from "../../state/events"
import DefaultLayout from "../../components/DefaultLayout"
import { Spinner, Table, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"
import Layout from "react-drawers"

const EventsPage = ({ history }) => {
  const [page, setPage] = useQueryParam("page", 1, qpInt())

  const goToPage = useCallback((e, p) => {
    e.preventDefault()
    if (p) setPage(p)
  }, [setPage])

  const filter = useMemo(() => ({
    page,
    ordering: "-id"
  }), [page])

  const [
    { list: events, loading: areEventsLoading, pagination, isDeleting },
    { deleteEvent, run: loadEvents }
  ] = useRunRj(EventsListRj, [filter])
  const [deletingEvent, setDeletingEvent] = useState(null)

  return (
    <DefaultLayout>
      <Layout.Content className="d-flex flex-column">
        <Layout.Master
          top={40}
          bottom={40}
          topOpenOnStart={true}
          bottomOpenOnStart={true}
        >
          <Layout absolute>
            <Layout.Top className="bg-lightgray3 d-flex flex-row justify-content-between align-items-center px-2">
              <h3 className="m-0">Gestione eventi</h3>
              {areEventsLoading && (
                <Spinner size="sm" />
              )}
            </Layout.Top>
            <Layout.Content className="overflow-auto">
              {events && events.length && (
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>Codice</th>
                      <th>Area</th>
                      <th>Data evento</th>
                      <th>Presentazione</th>
                      <th>Opzioni</th>
                    </tr>
                  </thead>
                  <tbody>
                    {events.map(event => (
                      <tr key={event.id}>
                        <td>{event.id}</td>
                        <td>{event.division_data.name}</td>
                        <td>{event.date}</td>
                        <td>{event.summary}</td>
                        <td>
                          <div className="d-flex flex-row">
                            <button
                              className="btn btn-sm btn-primary mr-1"
                              onClick={() => {
                                history.push(`/events/${event.id}`)
                              }}
                            >
                              {"Modifica"}
                            </button>
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => setDeletingEvent(event)}
                            >
                              {"Elimina"}
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Layout.Content>
            <Layout.Bottom className="d-flex flex-row justify-content-center align-items-center p-2 bg-lightgray3">
              <nav>
                <ul className="pagination m-0">
                  <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={e => goToPage(e, page - 1)}>
                      {"‹"}
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" onClick={e => goToPage(e)}>
                      {page}
                    </a>
                  </li>
                  <li className={`page-item ${page === pagination.numPages - 1 ? 'disabled' : ''}`}>
                    <a className="page-link" onClick={e => goToPage(e, page + 1)}>
                      {"›"}
                    </a>
                  </li>
                </ul>
              </nav>

              {deletingEvent && (
                <Modal size="sm" isOpen={true} toggle={() => setDeletingEvent(null)}>
                  <ModalHeader toggle={() => setDeletingEvent(null)}>Richiesta conferma</ModalHeader>
                  <ModalBody>
                    <p>Sei sicuro di voler <b>eliminare definitivamente</b> questo evento?</p>
                    <p>
                      <i>
                        {deletingEvent.summary}
                      </i>
                    </p>
                  </ModalBody>
                  <ModalFooter className="d-flex flex-row justify-content-end align-items-center">
                    <Button
                      type="button"
                      size="sm"
                      color="secondary"
                      disabled={isDeleting}
                      onClick={() => setDeletingEvent(null)}
                    >
                      {"Annulla"}
                    </Button>
                    <Button
                      type="button"
                      size="sm"
                      color="danger"
                      disabled={isDeleting}
                      onClick={() => {
                        deleteEvent
                          .onSuccess(() => {
                            setDeletingEvent(null)
                            loadEvents(filter)
                          })
                          .run(deletingEvent.id)
                      }}
                    >
                      {isDeleting && (
                        <Spinner size="sm" className="mr-2" />
                      )}
                      {"Elimina"}
                    </Button>
                  </ModalFooter>
                </Modal>
              )}
            </Layout.Bottom>
          </Layout>
        </Layout.Master>
      </Layout.Content>
    </DefaultLayout>
  )
}

export default EventsPage