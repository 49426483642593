import { orderBy } from "lodash"
import { rj, useRunRj } from "react-rocketjump"
import request from "superagent"
import { API_URL } from "../constants"

const NostraComunitaReleasesRj = rj({
  effectCaller: rj.configured(),
  effect: (token) => (path) => {
    return request
      .get(`${API_URL}/nostracomunita/releases/`)
      .set("Authorization", `Token ${token}`)
      .then(({ body }) => body)
  },
  mutations: {
    upload: rj.mutation.single({
      effect: (token) => (formdata) => {
        return request
          .post(`${API_URL}//nostracomunita/releases/`)
          .set("Authorization", `Token ${token}`)
          .send(formdata)
          .then(({ body }) => body)
      },
      updater: (state, result) => {
        return {
          ...state,
          data: orderBy(
            [...state.data, result],
            ["type", "name"],
            ["desc", "desc"]
          ),
        }
      },
    }),
  },
})

export function useNostraComunitaReleases() {
  return useRunRj(NostraComunitaReleasesRj, [])
}
