import { rj } from "react-rocketjump"
import API from "../lib/api"
import dayjs from "dayjs"

const ConfigurationState = rj({
  effectCaller: rj.configured(),
  effect: (token) => (id) => {
    return API.auth(token).get(`/confs/${id}/`, {
      date__gte: dayjs().format("YYYY-MM-DD"),
    }).toPromise()
  },
})

export default ConfigurationState
