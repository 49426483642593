import React from "react"
import { Input } from "reactstrap"
import ReactDatePicker from "react-datepicker"
import dayjs from "dayjs"

const DateField = ({ form, field, inputProps, invalid, ...others }) => {
  const date = field.value && new Date(field.value)

  return (
    <div className={invalid ? "is-invalid": ""}>
      <ReactDatePicker
        {...others}
        selected={date}
        onChange={date => form.setFieldValue(field.name, dayjs(date).format('YYYY-MM-DD'))}
        customInput={<Input invalid={invalid} {...inputProps} />}
      />
    </div>
  )
}

export default DateField