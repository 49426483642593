import React, { useCallback } from "react"
import { Piece } from "./piece"

export function TitlePiece({ props, tools }) {
  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    return false
  }, [])

  return (
    <Piece name="Titolo" tools={tools}>
      <form onSubmit={handleSubmit} className="form">
        <div className="form-group row">
          <label className="col-3">Contenuto</label>
          <div className="col-9">
            <input
              className="form-control form-control-sm"
              type="text"
              value={props.title ?? ""}
              onChange={(e) => {
                tools.setProp("title", e.target.value)
              }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-3">Allineamento</label>
          <div className="col-9">
            <select
              className="form-control form-control-sm"
              type="text"
              value={props.center ? "center" : "left"}
              onChange={(e) => {
                tools.setProp("center", e.target.value === "center")
              }}
            >
              <option value="left">A sinistra</option>
              <option value="center">Centrato</option>
            </select>
          </div>
        </div>
      </form>
    </Piece>
  )
}

TitlePiece.initialProps = {
  title: "",
  center: false,
}
TitlePiece.cmsName = "Titolo"
