import React, { useCallback, useState } from "react"
import {
  Button,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"

export function CreateFileModal({ isOpen, onConfirm, onCancel, onClosed }) {
  const [name, setName] = useState("")

  const handleFormSubmit = useCallback(
    (e) => {
      e.preventDefault()
      onConfirm(name)
      return false
    },
    [name, onConfirm]
  )

  return (
    <Modal isOpen={isOpen} toggle={onCancel} onClosed={onClosed}>
      <ModalHeader toggle={onCancel}>Nuovo file</ModalHeader>
      <ModalBody>
        <form onSubmit={handleFormSubmit}>
          <FormGroup>
            <Label>Inserisci il nome per il nuovo file</Label>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <FormText>
              Usa come estensione <code>.json</code> per una pagina o{" "}
              <code>.md</code> per un file di testo
            </FormText>
          </FormGroup>
        </form>
      </ModalBody>
      <ModalFooter className="text-right">
        <Button
          color="primary"
          onClick={() => {
            onConfirm(name)
          }}
        >
          Conferma
        </Button>
      </ModalFooter>
    </Modal>
  )
}
