import React, { useMemo, useState, useEffect } from "react"
import dayjs from "dayjs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowCircleLeft, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons"
import range from "lodash/range"
import "./Calendar.scss"

const MonthNames = [
  'Gennaio', 'Febbraio', 'Marzo', 'Aprile',
  'Maggio', 'Giugno', 'Luglio', 'Agosto',
  'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
]

const DayHeader = [
  'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa', 'Do'
]

const Calendar = ({ goToNextMonth, goToPrevMonth, month, year, onDateHover, selectedDate, onDateSelect, highlightDates }) => {
  const display = useMemo(() => {
    const matrix = new Array(6).fill(0).map(() => new Array(7).fill(null))
    const startDate = dayjs(new Date(year, month, 15)).startOf("month")
    const numberOfDays = startDate.daysInMonth()
    const firstDay = startDate.get("day")
    for (let i = 1; i <= numberOfDays; i++) {
      const row = Math.floor((i + firstDay - 1) / 7)
      const column = (i + firstDay - 1) % 7
      matrix[row][column] = i
    }
    return matrix
  }, [month, year])

  const [hoverDate, setHoverDate] = useState(null)

  const selectedDay = dayjs(selectedDate).get("date")
  const today = dayjs().format("YYYY-MM-DD")

  useEffect(() => {
    const dateToSend = hoverDate
      ? dayjs(
        new Date(year, month, hoverDate)
      ).format("YYYY-MM-DD")
      : null
    onDateHover && onDateHover(dateToSend)
  }, [hoverDate, month, onDateHover, year])

  return (
    <div className="calendar">
      <div className="calendar-navi">
        <div className="control left-control" onClick={goToPrevMonth}>
          <FontAwesomeIcon icon={faArrowCircleLeft} />
        </div>
        <div className="current-month-year">
          {`${MonthNames[month]} ${year}`}
        </div>
        <div className="control right-control">
          <FontAwesomeIcon icon={faArrowCircleRight} onClick={goToNextMonth} />
        </div>
      </div>
      <div className="calendar-row calendar-header">
        {range(7).map(n => (
          <div className="calendar-cell" key={n}>
            {DayHeader[n]}
          </div>
        ))}
      </div>
      {display.map((row, i) => (
        <div key={i} className="calendar-row">
          {row.map((cell, j) => {
            const date = cell
              ? dayjs(new Date(year, month, cell)).format("YYYY-MM-DD")
              : null
            const classNames = ["calendar-cell"]
            if (!cell) {
              classNames.push("placeholder")
            }
            if (cell && cell === selectedDay) {
              classNames.push("selected")
            }
            if (Array.isArray(highlightDates) && highlightDates.includes(date)) {
              classNames.push("highlight")
            }
            if (date === today) {
              classNames.push("today")
            }
            return (
              <div
                className={classNames.join(" ")}
                key={j}
                onMouseEnter={() => {
                  if (!!cell) {
                    setHoverDate(cell)
                  }
                }}
                onMouseLeave={() => {
                  if (!!cell && hoverDate === cell) {
                    setHoverDate(null)
                  }
                }}
                onClick={() => {
                  if (!!cell) {
                    onDateSelect && onDateSelect(
                      dayjs(
                        new Date(year, month, cell)
                      ).format("YYYY-MM-DD")
                    )
                  }
                }}
              >
                {cell || ""}
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

export default Calendar