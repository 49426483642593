import { last, sortBy } from "lodash"
import { useMemo } from "react"
import { rj, useRunRj } from "react-rocketjump"
import request from "superagent"
import { API_URL } from "../constants"

const CMSFilesRj = rj({
  effectCaller: rj.configured(),
  effect: (token) => (path) => {
    return request
      .get(`${API_URL}/cms/files/${path}`)
      .set("Authorization", `Token ${token}`)
      .then(({ body }) => body)
  },
  mutations: {
    createFolder: rj.mutation.single({
      effect: (token) => (path) => {
        return request
          .post(`${API_URL}/cms/files/${path}`)
          .query({ type: "directory" })
          .set("Authorization", `Token ${token}`)
          .then(() => last(path.split("/")))
      },
      updater: (state, result) => {
        return {
          ...state,
          data: sortBy(
            [...state.data, { type: "directory", name: result, size: 0 }],
            "type",
            "name"
          ),
        }
      },
    }),
    createFile: rj.mutation.single({
      effect: (token) => (path) => {
        return request
          .post(`${API_URL}/cms/files/${path}`)
          .query({ type: "file" })
          .set("Authorization", `Token ${token}`)
          .then(() => last(path.split("/")))
      },
      updater: (state, result) => {
        return {
          ...state,
          data: sortBy(
            [...state.data, { type: "file", name: result, size: 0 }],
            "type",
            "name"
          ),
        }
      },
    }),
    unlink: rj.mutation.single({
      effect: (token) => (path) => {
        return request
          .delete(`${API_URL}/cms/files/${path}`)
          .set("Authorization", `Token ${token}`)
          .then(() => last(path.split("/")))
      },
      updater: (state, result) => {
        return {
          ...state,
          data: state.data.filter(({ name: fname }) => fname !== result),
        }
      },
    }),
  },
})

export function useCMSFiles(path = "") {
  const args = useMemo(() => {
    return [path]
  }, [path])
  return useRunRj(CMSFilesRj, args)
}

const CMSFileContentRj = rj({
  effectCaller: rj.configured(),
  effect: (token) => (path) => {
    return request
      .get(`${API_URL}/cms/files/${path}`)
      .set("Authorization", `Token ${token}`)
      .then((r) => {
        return r.text
      })
  },
  mutations: {
    update: rj.mutation.single({
      effect: (token) => (path, data) => {
        return request
          .put(`${API_URL}/cms/files/${path}`)
          .set("Authorization", `Token ${token}`)
          .send(data)
          .then((r) => {
            return r.text
          })
      },
      updater: (state, result) => state,
    }),
  },
})

export function useCMSFileContent(path) {
  const args = useMemo(() => {
    return [path]
  }, [path])
  return useRunRj(CMSFileContentRj, args)
}

const CMSAssetsRj = rj({
  effectCaller: rj.configured(),
  effect: (token) => (path) => {
    return request
      .get(`${API_URL}/cms/assets/${path}`)
      .set("Authorization", `Token ${token}`)
      .then(({ body }) => body)
  },
  mutations: {
    createAsset: rj.mutation.single({
      effect: (token) => (path, data) => {
        return request
          .post(`${API_URL}/cms/assets/${path}`)
          .set("Authorization", `Token ${token}`)
          .send(data)
          .then((r) => {
            return path
          })
      },
      updater: (state, result) => {
        return {
          ...state,
          data: sortBy(
            [...state.data, { type: "file", name: last(result.split("/")) }],
            "type",
            "name"
          ),
        }
      },
    }),
  },
})

export function useCMSAssets(path = "") {
  const args = useMemo(() => {
    return [path]
  }, [path])
  return useRunRj(CMSAssetsRj, args)
}
