import { rj } from "react-rocketjump"
import rjList, { nextPreviousPaginationAdapter } from "react-rocketjump/plugins/list"
import { API_URL } from "../constants"
import request from "superagent"

export const EventsListRj = rj(
  rjList({
    pageSize: 100,
    pagination: nextPreviousPaginationAdapter
  }),
  {
    effectCaller: rj.configured(),
    effect: token => (filters = {}) => {
      return request.get(`${API_URL}/news_item/`)
        .set("Authorization", `Token ${token}`)
        .query(filters)
        .then(({ body }) => body)
    },
    mutations: {
      deleteEvent: rj.mutation.single({
        effect: token => eventId => {
          return request.delete(`${API_URL}/news_item/${eventId}/`)
            .set("Authorization", `Token ${token}`)
            .then(() => eventId)
        },
        updater: (state, result) => {
          return {
            ...state,
            data: {
              ...state.data,
              list: state.data.list.filter(item => item.id !== result)
            }
          }
        }
      })
    },
    computed: {
      isDeleting: "@mutation.deleteEvent.pending"
    }
  }
)

export const EventRj = rj({
  effectCaller: rj.configured(),
  effect: token => id => {
    return request.get(`${API_URL}/news_item/${id}/`)
      .set("Authorization", `Token ${token}`)
      .then(({ body }) => body)
  },
  mutations: {
    update: rj.mutation.single({
      effect: token => (id, data) => {
        return request.patch(`${API_URL}/news_item/${id}/`)
          .set("Authorization", `Token ${token}`)
          .send(data)
          .then(({ body }) => body)
      },
      updater: "updateData"
    }),
    create: rj.mutation.single({
      effect: token => data => {
        return request.post(`${API_URL}/news_item/`)
          .set("Authorization", `Token ${token}`)
          .send(data)
          .then(({ body }) => body)
      },
      updater: "updateData"
    }),
    setImage: rj.mutation.single({
      effect: token => (id, new_image) => {
        return request.post(`${API_URL}/news_item/${id}/set_image/`)
          .set("Authorization", `Token ${token}`)
          .attach("image", new_image)
          .then(({ body }) => body)
      },
      updater: "updateData"
    }),
    unsetImage: rj.mutation.single({
      effect: token => (id, new_image) => {
        return request.post(`${API_URL}/news_item/${id}/unset_image/`)
          .set("Authorization", `Token ${token}`)
          .then(({ body }) => body)
      },
      updater: "updateData"
    }),
    attachFile: rj.mutation.single({
      effect: token => (id, filename, file) => {
        return request.post(`${API_URL}/news_item/${id}/add_attachment/`)
          .set("Authorization", `Token ${token}`)
          .field("name", filename)
          .attach("file", file)
          .then(({ body }) => body)
      },
      updater: "updateData"
    }),
    removeFile: rj.mutation.single({
      effect: token => (id, index) => {
        return request.post(`${API_URL}/news_item/${id}/remove_attachment/`)
          .set("Authorization", `Token ${token}`)
          .field("index", index)
          .then(({ body }) => body)
      },
      updater: "updateData"
    }),
  }
})
