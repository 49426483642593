import React from "react"
import { useRunRj } from "react-rocketjump"
import { DivisionListRj } from "../state/divisions"
import { Spinner, Input } from "reactstrap"

const DivisionField = ({ form, field, ...others }) => {
  const [{ data: divisions }] = useRunRj(DivisionListRj)

  if (!divisions) {
    return <Spinner size="sm" />
  }

  return (
    <Input
      {...others}
      type="select"
      value={field.value || "none"}
      onChange={e => {
        form.setFieldValue(field.name, e.target.value)
      }}
    >
      <option disabled value="none">Seleziona da elenco</option>
      {divisions.map(division => (
        <option key={division.name} value={division.name}>{division.name}</option>
      ))}
    </Input>
  )
}

export default DivisionField