import { mapValues, keyBy } from "lodash"

export const API_URL = "/api"
export const BASENAME =
  process.env.NODE_ENV === "production" ? "/login" : undefined

export const CMS_BASE_URL_FOR_ASSETS = process.env.NODE_ENV === "production" ? "https://www.oratoriobrembate.it/media/public" : "http://localhost:8000/media/public"

export const CONF_NAMES = [
  { label: "Prefestiva 17:30", value: "prefestiva-17-30" },
  { label: "Prefestiva 18:30", value: "prefestiva-18-30" },
  { label: "Festiva 8:00", value: "festiva-8-00" },
  { label: "Festiva 9:30", value: "festiva-9-30" },
  { label: "Festiva 10:00", value: "festiva-10-00" },
  { label: "Festiva 11:00", value: "festiva-11-00" },
  { label: "Festiva 11:00 (oratorio)", value: "festiva-11-00-oratorio" },
  { label: "Festiva 17:30", value: "festiva-17-30" },
  { label: "Festiva 18:30", value: "festiva-18-30" },
  { label: "Messa nella notte", value: "natale-24-00" },
  { label: "Messe adulti (inverno)", value: "ADULTI_INVERNO" },
]

export const CONF_NAMES_GROUPS = {
  ADULTI_INVERNO: [
    { label: "Prefestiva 17:30", value: "prefestiva-17-30" },
    { label: "Festiva 8:00", value: "festiva-8-00" },
    { label: "Festiva 9:30", value: "festiva-9-30" },
    { label: "Festiva 17:30", value: "festiva-17-30" },
  ],
}

export const CONF_NAME_MAP = mapValues(keyBy(CONF_NAMES, "value"), "label")
