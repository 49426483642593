import React from "react"

const CheckboxField = ({ field, form }) => {
  return (
    <div style={{ height: 33.5 }} className="d-flex flex-row align-items-center">
      <input
        type="checkbox"
        checked={!!field.value}
        onChange={e => form.setFieldValue(field.name, e.target.checked)}
      />
    </div>
  )
}

export default CheckboxField