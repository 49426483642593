import { rj } from "react-rocketjump"
import API from "../lib/api"
import dayjs from "dayjs"

const ConfigurationsState = rj({
  effectCaller: rj.configured(),
  effect: (token) => () => {
    return API.auth(token)
      .get("/confs/", {
        date__gte: dayjs().format("YYYY-MM-DD"),
      })
      .toPromise()
  },
  mutations: {
    deleteConf: {
      effect: (token) => (confId) => {
        return API.auth(token)
          .mapResponse(() => confId)
          .delete(`/confs/${confId}/`)
          .toPromise()
      },
      updater: (state, result) => {
        return {
          ...state,
          data: state.data.filter((conf) => conf.id !== result),
        }
      },
    },
  },
})

export default ConfigurationsState
