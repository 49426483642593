import React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import dayjs from "dayjs"
import { EVENT_TYPE_DESCRIPTIONS } from "./utils"
import { Formik, Field } from "formik"
import DateField from "../../components/DateField"
import InputField from "../../components/InputField"
import CheckboxField from "../../components/CheckboxField"

const Entry = ({ caption, value }) => {
  return (
    <div className="row p-1">
      <div className="col-4 d-flex flex-row align-items-center">
        {caption}
      </div>
      <div className="col-8">
        {value}
      </div>
    </div>
  )
}

const EventRead = ({ event, toggle, changeMode }) => (
  <Modal size="md" isOpen={true} toggle={toggle}>
    <ModalHeader toggle={toggle}>
      {"Scheda prenotazione"}
    </ModalHeader>
    <ModalBody>
      <Entry caption="Data" value={dayjs(event.data).format("DD/MM/YYYY")} />
      <Entry caption="Tipo" value={EVENT_TYPE_DESCRIPTIONS[event.evento]} />
      <Entry caption="Referente" value={event.referente} />
      <Entry caption="Contatto" value={event.contatto} />
      {event.evento === "COMPLEANNO" && (
        <Entry
          caption="Utilizzo campetto"
          value={
            event.prenota_campetto
              ? <FontAwesomeIcon icon={faCheck} />
              : <FontAwesomeIcon icon={faTimes} />
          }
        />
      )}
      <Entry
        caption="Pagato"
        value={
          event.pagato
            ? <FontAwesomeIcon icon={faCheck} />
            : <FontAwesomeIcon icon={faTimes} />
        }
      />
      <Entry
        caption="Numero ricevuta"
        value={event.ricevuta || "-"}
      />
    </ModalBody>
    <ModalFooter>
      <Button
        color="warning"
        onClick={() => changeMode("edit")}
      >
        {"Modifica"}
      </Button>
      <Button
        color="danger"
        onClick={() => changeMode("delete_confirm")}
      >
        {"Elimina"}
      </Button>
    </ModalFooter>
  </Modal>
)

const EventEdit = ({ event, toggle, updateEvent, changeMode }) => (
  <Formik
    initialValues={event}
    onSubmit={(values, actions) => {
      updateEvent(values).then(() => {
        toggle()
      })
    }}
  >
    {({ values, handleSubmit }) => (
      <Modal size="md" isOpen={true}>
        <ModalHeader toggle={toggle}>
          {"Modifica prenotazione"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Entry
              caption="Data"
              value={
                <Field
                  name="data"
                  component={DateField}
                  dateFormat="dd/MM/yyyy"
                />
              }
            />
            <Entry
              caption="Tipo"
              value={
                <Field
                  name="evento"
                  component={InputField}
                  type="select"
                >
                  <option value="COMPLEANNO">{EVENT_TYPE_DESCRIPTIONS.COMPLEANNO}</option>
                  <option value="GRUPPO">{EVENT_TYPE_DESCRIPTIONS.GRUPPO}</option>
                  <option value="SALONE">{EVENT_TYPE_DESCRIPTIONS.SALONE}</option>
                </Field>
              }
            />
            <Entry
              caption="Referente"
              value={
                <Field
                  name="referente"
                  component={InputField}
                />
              }
            />
            <Entry
              caption="Contatto"
              value={
                <Field
                  name="contatto"
                  component={InputField}
                />
              }
            />
            {values.evento === "COMPLEANNO" && (
              <Entry
                caption="Utilizzo campetto"
                value={
                  <Field
                    name="prenota_campetto"
                    component={CheckboxField}
                    type="checkbox"
                  />
                }
              />
            )}
            <Entry
              caption="Pagato"
              value={
                <Field
                  name="pagato"
                  component={CheckboxField}
                  type="checkbox"
                />
              }
            />
            <Entry
              caption="Numero ricevuta"
              value={
                <Field
                  name="ricevuta"
                  component={InputField}
                />
              }
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            type="submit"
            onClick={handleSubmit}
          >
            {"Salva"}
          </Button>
          {values.id && (
            <Button
              color="danger"
              type="button"
              onClick={() => changeMode("delete_confirm")}
            >
              {"Elimina"}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    )}
  </Formik>
)

const EventDelete = ({ event, changeMode, deleteEvent, toggle }) => {
  return (
    <Modal size="md" isOpen={true}>
      <ModalHeader toggle={toggle}>
        {"Elimina prenotazione"}
      </ModalHeader>
      <ModalBody>
        <p>Vuoi davvero eliminare la prenotazione di {event.referente} per il giorno {dayjs(event.data).format("DD/MM/YYYY")}?</p>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          type="button"
          onClick={() => {
            toggle()
            deleteEvent(event)
          }}
        >
          {"Conferma"}
        </Button>
        <Button
          color="secondary"
          type="button"
          onClick={() => toggle()}
        >
          {"Annulla"}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const EventCreate = ({ createEvent, selectedDate, ...props }) => {
  const EventDefault = {
    referente: "",
    contatto: "",
    data: selectedDate,
    evento: "COMPLEANNO",
    prenota_campetto: false,
    pagato: false,
    ricevuta: ""
  }

  return (
    <EventEdit
      {...props}
      event={EventDefault}
      updateEvent={createEvent}
    />
  )
}

const EventModal = ({ mode = "read", ...props }) => {
  if (mode === "read") {
    return <EventRead {...props} />
  }
  if (mode === "edit") {
    return <EventEdit {...props} />
  }
  if (mode === "delete_confirm") {
    return <EventDelete {...props} />
  }
  if (mode === "create") {
    return <EventCreate {...props} />
  }
  return null
}

export default EventModal