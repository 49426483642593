import React from "react"
import dayjs from "dayjs"
import { get } from "lodash"
import { useRunRj } from "react-rocketjump"
import { useHistory } from "react-router-dom"
import { CONF_NAME_MAP } from "../../constants"
import ConfigurationsState from "../../state/configurations"
import DefaultLayout from "../../components/DefaultLayout"
import Layout from "react-drawers"

export default function Canti() {
  const [{ data: configurations }, { deleteConf }] =
    useRunRj(ConfigurationsState)
  const history = useHistory()

  return (
    <DefaultLayout>
      <Layout.Content className="d-flex flex-column">
        <Layout.Master
          top={40}
          bottom={40}
          topOpenOnStart={true}
          bottomOpenOnStart={true}
        >
          <Layout absolute>
            <Layout.Top className="bg-lightgray3 d-flex flex-row justify-content-between align-items-center px-2">
              <h3>Canti prossime domeniche</h3>
            </Layout.Top>
            <Layout.Content className="d-flex flex-row justify-content-center overflow-auto">
              <div className="container py-5">
                <button
                  type="button"
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    history.push("/canti/new")
                  }}
                >
                  <i className="fas fa-plus mr-2" />
                  Aggiungi
                </button>
                {configurations &&
                  configurations.map((conf) => (
                    <p className="border-dark border-bottom d-flex flex-row justify-content-between align-items-center">
                      {dayjs(conf.date).format("DD/MM/YYYY")}
                      {" - "}
                      {get(CONF_NAME_MAP, conf.name, conf.name)}
                      <div className="flex-1" />
                      <button
                        className="btn btn-sm btn-primary m-1"
                        onClick={() => {
                          history.push(`/canti/${conf.id}`)
                        }}
                      >
                        <i className="fas fa-pencil-alt" />
                      </button>
                      <button
                        className="btn btn-sm btn-danger m-1"
                        onClick={() => {
                          deleteConf(conf.id)
                        }}
                      >
                        <i className="fas fa-trash-alt" />
                      </button>
                    </p>
                  ))}
              </div>
            </Layout.Content>
          </Layout>
        </Layout.Master>
      </Layout.Content>
    </DefaultLayout>
  )
}
