import React from "react"
import dayjs from "dayjs"
import { Field, FieldArray, Formik } from "formik"
import { get } from "lodash"
import { useRunRj } from "react-rocketjump"
import ChoiceField from "../../components/ChoiceField"
import FileField from "../../components/FileFieldBs"
import { CONF_NAME_MAP } from "../../constants"
import API from "../../lib/api"
import ConfigurationState from "../../state/configuration"
import SongsState from "../../state/songs"
import { useAuthUser } from "use-eazy-auth"
import DefaultLayout from "../../components/DefaultLayout"
import Layout from "react-drawers"

export default function EditConfCanti({ history, match }) {
  const [{ data: songs }] = useRunRj(SongsState)
  const [{ data: conf }] = useRunRj(ConfigurationState, [match.params.id])
  const { token } = useAuthUser()

  if (!conf || !songs) {
    return null
  }

  return (
    <DefaultLayout>
      <Layout.Content className="d-flex flex-column">
        <Layout.Master
          top={40}
          bottom={40}
          topOpenOnStart={true}
          bottomOpenOnStart={true}
        >
          <Layout absolute>
            <Layout.Top className="bg-lightgray3 d-flex flex-row justify-content-between align-items-center px-2">
              <h3>Modifica configurazione canti</h3>
            </Layout.Top>
            <Layout.Content className="d-flex flex-row justify-content-center overflow-auto">
              <Formik
                initialValues={conf}
                onSubmit={(values, actions) => {
                  const fd = new FormData()
                  if (values.booklet) fd.append("booklet", values.booklet)
                  fd.append("next_songs", JSON.stringify(values.songs))
                  return API.auth(token)
                    .patch(`/confs/${conf.id}/`, fd)
                    .toPromise()
                    .then(() => {
                      history.goBack()
                    })
                }}
                validate={(values) => {
                  const errors = {}
                  if (values.songs.length === 0) {
                    errors.songs = "This field cannot be empty"
                  }
                  return errors
                }}
              >
                {(formik) => (
                  <div className="container pt-5">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="form-group row">
                        <label className="form-label col-md-3 col-12">
                          Nome
                        </label>
                        <div className="col-md-9 col-12 px-0">
                          {get(
                            CONF_NAME_MAP,
                            formik.values.name,
                            formik.values.name
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="form-label col-md-3 col-12">
                          Data
                        </label>
                        <div className="col-md-9 col-12 pl-0">
                          {dayjs(formik.values.date).format("DD/MM/YYYY")}
                        </div>
                      </div>
                      <FieldArray name="songs">
                        {(helpers) => (
                          <div>
                            {formik.values.songs.map((song, i) => (
                              <div className="form-group row">
                                <label className="form-label col-md-3 col-12">
                                  {i === 0 ? "Canti" : null}
                                </label>
                                <div className="col-md-9 col-12 d-flex flex-row aling-items-stretch justify-content-start pl-0">
                                  <Field
                                    name={`songs.${i}.id`}
                                    component={ChoiceField}
                                    className="flex-1"
                                    options={songs.map((song) => ({
                                      label: song.title,
                                      value: song.id,
                                    }))}
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary ml-3"
                                    onClick={() => {
                                      helpers.swap(i, i - 1)
                                    }}
                                    disabled={i === 0}
                                  >
                                    <i className="fas fa-arrow-up" />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary ml-1"
                                    onClick={() => {
                                      helpers.swap(i, i + 1)
                                    }}
                                    disabled={
                                      formik.values.songs.length - 1 === i
                                    }
                                  >
                                    <i className="fas fa-arrow-down" />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-danger ml-3"
                                    onClick={() => {
                                      helpers.remove(i)
                                    }}
                                  >
                                    <i className="fas fa-trash-alt" />
                                  </button>
                                </div>
                              </div>
                            ))}
                            <div className="form-group row">
                              <div className="form-label col-md-3 col-12">
                                {formik.values.songs.length === 0 && "Canti"}
                              </div>
                              <div className="col-md-9 col-12 px-0">
                                <button
                                  type="button"
                                  className="btn btn-secondary btn-sm"
                                  onClick={() => {
                                    helpers.push({ id: songs[0].id })
                                  }}
                                >
                                  Aggiungi canto
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </FieldArray>
                      <div className="form-group row">
                        <label className="form-label col-md-3 col-12">
                          Libretto
                        </label>
                        <Field
                          name="booklet"
                          component={FileField}
                          className="col-md-9 col-12 px-0"
                        />
                      </div>
                      <div className="text-right">
                        <button
                          type="button"
                          className="btn btn-sm btn-secondary mr-3"
                          onClick={() => {
                            history.goBack()
                          }}
                        >
                          Indietro
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-success"
                          disabled={!formik.isValid}
                        >
                          Salva
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </Formik>
            </Layout.Content>
          </Layout>
        </Layout.Master>
      </Layout.Content>
    </DefaultLayout>
  )
}
