import React, { useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { pieces } from "./conf"

export function PickElementModal({ isOpen, onConfirm, onCancel, onClosed }) {
  const [selected, setSelected] = useState(null)

  return (
    <Modal isOpen={isOpen} toggle={onCancel} size="lg" onClosed={onClosed}>
      <ModalHeader toggle={onCancel}>Aggiungi elemento</ModalHeader>
      <ModalBody>
        <p>Seleziona il tipo di oggetto da inserire</p>
        <div className="container">
          <div className="row">
            {Object.entries(pieces).map(([key, cmp]) => (
              <div className="col-4 pb-3" key={key}>
                <Button
                  className="w-100 h-100"
                  color={selected === key ? "primary" : "secondary"}
                  onClick={() => setSelected(key)}
                >
                  {cmp.cmsName}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          disabled={!selected}
          onClick={() => {
            onConfirm(selected)
          }}
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>
  )
}
