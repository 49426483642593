import React from "react"
import { Input } from "reactstrap"

const InputField = ({ form, field, ...others }) => {
  return (
    <Input
      {...others}
      {...field}
    />
  )
}

export default InputField