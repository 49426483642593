import React from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"

export function ConfirmDeleteModal({
  path,
  isOpen,
  onConfirm,
  onCancel,
  onClosed,
}) {
  return (
    <Modal isOpen={isOpen} toggle={onCancel} onClosed={onClosed}>
      <ModalHeader toggle={onCancel}>Nuova cartella</ModalHeader>
      <ModalBody>
        <p>
          Vuoi veramente eliminare <b>{path}</b>?
        </p>
        <p>
          Questa operazione è{" "}
          <b>
            <u>irreversibile</u>
          </b>
        </p>
      </ModalBody>
      <ModalFooter className="text-right">
        <Button
          className="mr-2"
          color="secondary"
          onClick={() => {
            onCancel()
          }}
        >
          Annulla
        </Button>
        <Button
          color="primary"
          onClick={() => {
            onConfirm()
          }}
        >
          Conferma
        </Button>
      </ModalFooter>
    </Modal>
  )
}
