import dayjs from "dayjs"
import { Field, Formik } from "formik"
import React, { useCallback } from "react"
import Layout from "react-drawers"
import { useRj } from "react-rocketjump"
import { Button, Col, Form, FormGroup, FormText, Label, Spinner, FormFeedback } from "reactstrap"
import DateField from "../../components/DateField"
import DefaultLayout from "../../components/DefaultLayout"
import FileField from "../../components/FileField"
import { EventRj } from "../../state/events"

const SUNDAY_DEFAULT = {
  sunday: null,
  file: null
}

async function persistEvent(values, actions) {
  const { sunday, file } = values
  const sendData = {
    division: "Foglio della Settimana",
    summary: `Foglio degli avvisi ${dayjs(sunday).format("DD/MM/YYYY")}`,
    description: `Scarica ora il nuovo foglio degli avvisi di Domenica ${dayjs(sunday).format("DD/MM/YYYY")}`,
    date: sunday,
    time: null,
    publish_period_start: dayjs(sunday).subtract(1, "day").format("YYYY-MM-DD"),
    publish_period_end: dayjs(sunday).add(6, "day").format("YYYY-MM-DD")
  }
  let event = await actions.create.asPromise(sendData)
  event = await actions.attachFile(event.id, file.file.name, file.file)
  return event
}

const SundayUploadPage = ({ history, match }) => {

  const [,
    {
      run: loadEvent,
      clean,
      ...eventActions
    }
  ] = useRj(EventRj)

  const handleSubmit = useCallback((values, actions) => {
    persistEvent(values, eventActions)
      .then(() => {
        actions.setSubmitting(false)
        history.push("/events")
      })
      .catch(err => {
        console.log(err)
        actions.setSubmitting(false)
        if (err && err.response && err.response.body && err.response.body.detail) {
          alert(err.response.body.detail)
        }
      })
  }, [eventActions, history])

  return (
    <DefaultLayout>
      <Layout.Content className="d-flex flex-column">
        <Layout.Master
          top={40}
          bottom={40}
          topOpenOnStart={true}
          bottomOpenOnStart={true}
        >
          <Layout absolute>
            <Layout.Top className="bg-lightgray3 d-flex flex-row justify-content-between align-items-center px-2">
              <h3 className="m-0">{"Inserisci foglio degli avvisi"}</h3>
            </Layout.Top>
            <Layout.Content className="d-flex flex-row justify-content-center overflow-auto">
              <Formik
                initialValues={SUNDAY_DEFAULT}
                enableReinitialize={true}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit, isSubmitting, values }) => (
                  <Form onSubmit={handleSubmit} className="col-10 col-md-8">
                    <div className="pt-2" />
                    <FormGroup row>
                      <Label sm={4}>
                        {"Domenica di riferimento"}
                      </Label>
                      <Col sm={8}>
                        <Field
                          name="sunday"
                          component={DateField}
                          dateFormat="dd/MM/yyyy"
                          invalid={values.sunday && dayjs(values.sunday).get("day") !== 0}
                        />
                        <FormText className="text-right">Il foglio sarà visibile in home page dal sabato precedente a quello successivo</FormText>
                        {values.sunday && dayjs(values.sunday).get("day") !== 0 && (
                          <FormFeedback className="text-right">La data selezionata non è domenica</FormFeedback>
                        )}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label sm={4}>
                        {"File (PDF)"}
                      </Label>
                      <Col sm={8}>
                        <Field
                          name="file"
                          component={FileField}
                        />
                      </Col>
                    </FormGroup>
                    <div className="d-flex flex-row justify-content-between">
                      <Button
                        color="secondary"
                        type="button"
                        disabled={isSubmitting}
                        onClick={() => history.goBack()}
                      >Annulla</Button>
                      <Button
                        color="success"
                        type="submit"
                        disabled={isSubmitting || !values.sunday || dayjs(values.sunday).get("day") !== 0 || values.file === null}
                      >
                        {isSubmitting && (
                          <Spinner size="sm" className="mr-2" />
                        )}
                        {"Salva"}
                      </Button>
                    </div>
                    <div className="pt-2" />
                  </Form>
                )}
              </Formik>
            </Layout.Content>
          </Layout>
        </Layout.Master>
      </Layout.Content>
    </DefaultLayout>
  )
}

export default SundayUploadPage