import React from "react"
import dayjs from "dayjs"
import { Field, FieldArray, Formik } from "formik"
import { useRunRj } from "react-rocketjump"
import ChoiceField from "../../components/ChoiceField"
import FileField from "../../components/FileFieldBs"
import InputField from "../../components/InputFieldBs"
import { CONF_NAMES, CONF_NAMES_GROUPS } from "../../constants"
import API from "../../lib/api"
import SongsState from "../../state/songs"
import DefaultLayout from "../../components/DefaultLayout"
import Layout from "react-drawers"
import { useAuthUser } from "use-eazy-auth"

export default function AddConfCanti({ history }) {
  const [{ data: songs }] = useRunRj(SongsState)
  const { token } = useAuthUser()

  return (
    <DefaultLayout>
      <Layout.Content className="d-flex flex-column">
        <Layout.Master
          top={40}
          bottom={40}
          topOpenOnStart={true}
          bottomOpenOnStart={true}
        >
          <Layout absolute>
            <Layout.Top className="bg-lightgray3 d-flex flex-row justify-content-between align-items-center px-2">
              <h3>Crea configurazione canti</h3>
            </Layout.Top>
            <Layout.Content className="d-flex flex-row justify-content-center overflow-auto">
              <Formik
                initialValues={{
                  date: dayjs()
                    .endOf("week")
                    .add(1, "day")
                    .format("YYYY-MM-DD"),
                  name: CONF_NAMES[0].value,
                  songs: [],
                  booklet: null,
                }}
                onSubmit={(values, actions) => {
                  const awaits = []
                  const name = values.name
                  let names = []
                  if (CONF_NAMES_GROUPS[name]) {
                    names = CONF_NAMES_GROUPS[name].map((o) => o.value)
                  } else {
                    names = [name]
                  }
                  for (const xname of names) {
                    const fd = new FormData()
                    fd.append("name", xname)
                    fd.append("date", values.date)
                    if (values.booklet) {
                      fd.append("booklet", values.booklet)
                    }
                    fd.append("next_songs", JSON.stringify(values.songs))
                    awaits.push(API.auth(token).post("/confs", fd).toPromise())
                  }
                  return Promise.all(awaits).then(() => {
                    history.goBack()
                  })
                }}
                validate={(values) => {
                  const errors = {}
                  if (values.songs.length === 0) {
                    errors.songs = "This field cannot be empty"
                  }
                  return errors
                }}
              >
                {(formik) => (
                  <div className="container pt-5">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="form-group row">
                        <label className="form-label col-md-3 col-12">
                          Nome
                        </label>
                        <Field
                          name="name"
                          component={ChoiceField}
                          className="col-md-9 col-12"
                          options={CONF_NAMES}
                        />
                      </div>
                      <div className="form-group row">
                        <label className="form-label col-md-3 col-12">
                          Data
                        </label>
                        <Field
                          name="date"
                          component={InputField}
                          type="date"
                          className="col-md-9 col-12"
                        />
                      </div>
                      <FieldArray name="songs">
                        {(helpers) => (
                          <div>
                            {formik.values.songs.map((song, i) => (
                              <div className="form-group row">
                                <label className="form-label col-md-3 col-12">
                                  {i === 0 ? "Canti" : null}
                                </label>
                                <div className="col-md-9 col-12 d-flex flex-row aling-items-stretch justify-content-start p-0">
                                  <Field
                                    name={`songs.${i}.id`}
                                    component={ChoiceField}
                                    className="flex-1"
                                    options={songs.map((song) => ({
                                      label: song.title,
                                      value: song.id,
                                    }))}
                                  />
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary ml-3"
                                    onClick={() => {
                                      helpers.swap(i, i - 1)
                                    }}
                                    disabled={i === 0}
                                  >
                                    <i className="fas fa-arrow-up" />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary ml-1"
                                    onClick={() => {
                                      helpers.swap(i, i + 1)
                                    }}
                                    disabled={
                                      formik.values.songs.length - 1 === i
                                    }
                                  >
                                    <i className="fas fa-arrow-down" />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-danger ml-3"
                                    onClick={() => {
                                      helpers.remove(i)
                                    }}
                                  >
                                    <i className="fas fa-trash-alt" />
                                  </button>
                                </div>
                              </div>
                            ))}
                            <div className="form-group row">
                              <div className="form-label col-md-3 col-12">
                                {formik.values.songs.length === 0 && "Canti"}
                              </div>
                              <div className="col-md-9 col-12 px-0">
                                <button
                                  type="button"
                                  className="btn btn-secondary btn-sm"
                                  onClick={() => {
                                    helpers.push({ id: songs[0].id })
                                  }}
                                >
                                  Aggiungi canto
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </FieldArray>
                      <div className="form-group row">
                        <label className="form-label col-md-3 col-12">
                          Libretto
                        </label>
                        <Field
                          name="booklet"
                          component={FileField}
                          className="col-md-9 col-12 px-0"
                        />
                      </div>
                      <div className="text-right">
                        <button
                          type="button"
                          className="btn btn-sm btn-secondary mr-3"
                          onClick={() => {
                            history.goBack()
                          }}
                        >
                          Indietro
                        </button>
                        <button
                          type="submit"
                          className="btn btn-sm btn-success"
                          disabled={!formik.isValid}
                        >
                          Salva
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </Formik>
            </Layout.Content>
          </Layout>
        </Layout.Master>
      </Layout.Content>
    </DefaultLayout>
  )
}
