import React, { useEffect, useState } from "react";
import { useAuthState, useAuthActions } from "use-eazy-auth";

const Login = () => {
  const { loginLoading, loginError } = useAuthState();
  const { login, clearLoginError } = useAuthActions();
  // login credentials state
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // clear login error on unmount
  useEffect(() => () => clearLoginError(), [clearLoginError]);
  // clear login error when email or password changes
  useEffect(() => {
    clearLoginError();
  }, [username, password, clearLoginError]);
  return (
    <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-stretch">
      <div className="row no-gutters">
        <div className="col-md-4 offset-md-4">
          <div className="text-center">
            <h4>Oratorio Brembate</h4>
            <p>Inserisci username e password per accedere</p>
          </div>
          <form
            className="p-2 mt-2"
            onSubmit={e => {
              e.preventDefault();
              if (username !== "" && password !== "") {
                login({ username, password });
              }
            }}
          >
            <div className="form-group">
              <input
                className="form-control"
                placeholder="username"
                name="username"
                type="text"
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                name="password"
                className="form-control"
                placeholder="password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <button
              className="btn-block btn btn-sm btn-primary"
              type="submit"
              disabled={loginLoading}
            >
              {!loginLoading ? "Login" : "Logged in..."}
            </button>
            {loginError && (
              <div className="alert alert-danger mt-2">
                Username o password errati.
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
