import { faArrowLeft, faSave, faUndo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { last } from "lodash"
import React, { useCallback, useMemo, useState } from "react"
import Layout from "react-drawers"
import { Button } from "reactstrap"
import DefaultLayout from "../../components/DefaultLayout"
import { useCMSFileContent } from "../../hooks/useCMSFiles"
import { AddItemHandle } from "./AddItemHandle"
import { pieces } from "./conf"
import { Piece } from "./pieces/piece"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { PickElementModal } from "./PickElementModal"

function useCurriedCallback(cb, ...curryArgs) {
  return useCallback(
    (...args) => {
      return cb(...curryArgs, ...args)
    },
    [cb, curryArgs]
  )
}

function EditorBinder({ component, index, tools, ...props }) {
  const moveUp = useCurriedCallback(tools.moveUp, index)
  const moveDown = useCurriedCallback(tools.moveDown, index)
  const setProp = useCurriedCallback(tools.setProp, index)
  const remove = useCurriedCallback(tools.remove, index)
  const boundTools = useMemo(
    () => ({
      moveUp,
      moveDown,
      setProp,
      remove,
    }),
    [moveDown, moveUp, setProp, remove]
  )
  const Cmp = component
  return <Cmp {...props} tools={boundTools} />
}

function CMSEditorSafe({ history, pageName, initialState, onSave }) {
  const [pageData, setPageData] = useState(initialState.items)
  const [addModalState, addModalActions] = useModalTrigger()

  const setProp = useCallback((index, prop, value) => {
    setPageData((prevPageData) => {
      return prevPageData.map((item, i) => {
        if (index !== i) {
          return item
        } else {
          return {
            ...item,
            props: {
              ...item.props,
              [prop]: value,
            },
          }
        }
      })
    })
  }, [])

  const moveUp = useCallback((index) => {
    if (index === 0) {
      return
    }
    setPageData((prevPageData) => {
      const nextPageData = prevPageData.slice(0)
      nextPageData[index - 1] = prevPageData[index]
      nextPageData[index] = prevPageData[index - 1]
      return nextPageData
    })
  }, [])

  const moveDown = useCallback((index) => {
    setPageData((prevPageData) => {
      if (index === prevPageData.length - 1) {
        return prevPageData
      }
      const nextPageData = prevPageData.slice(0)
      nextPageData[index + 1] = prevPageData[index]
      nextPageData[index] = prevPageData[index + 1]
      return nextPageData
    })
  }, [])

  const remove = useCallback((index) => {
    setPageData((prevPageData) => {
      const nextPageData = prevPageData.slice(0)
      nextPageData.splice(index, 1)
      return nextPageData
    })
  }, [])

  const insertBefore = useCallback((itemType, index) => {
    setPageData((prevPageData) => {
      const nextPageData = [...prevPageData]
      nextPageData.splice(index, 0, {
        type: itemType,
        props: pieces[itemType].initialProps || {},
      })
      return nextPageData
    })
  }, [])

  const insertLast = useCallback((itemType) => {
    setPageData((prevPageData) => {
      const nextPageData = [
        ...prevPageData,
        { type: itemType, props: pieces[itemType].initialProps || {} },
      ]
      return nextPageData
    })
  }, [])

  const tools = useMemo(
    () => ({
      moveUp,
      moveDown,
      setProp,
      remove,
    }),
    [moveDown, moveUp, setProp, remove]
  )

  return (
    <DefaultLayout>
      <Layout.Content className="bg-lightgray4 overflow-y-auto">
        {pageData && (
          <div>
            <div className="row justify-content-center mb-3">
              <div className="col-10 col-xl-8">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="w-25">
                    <span
                      className="pointer"
                      onClick={() => {
                        history.goBack()
                      }}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </span>
                  </div>
                  <h3 className="py-3 text-center">{pageName}</h3>
                  <div className="w-25 text-right">
                    <Button
                      color="secondary"
                      size="sm"
                      className="mr-2"
                      onClick={() => {
                        setPageData(initialState)
                      }}
                    >
                      <FontAwesomeIcon icon={faUndo} />
                      <span> Annulla</span>
                    </Button>
                    <Button
                      color="success"
                      size="sm"
                      onClick={() => {
                        onSave(JSON.stringify({ items: pageData }))
                      }}
                    >
                      <FontAwesomeIcon icon={faSave} />
                      <span> Salva</span>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {pageData.map((item, i) => {
              const Cmp = pieces[item.type] || Piece
              return (
                <React.Fragment key={i}>
                  <AddItemHandle onClick={() => addModalActions.open(i)} />
                  <EditorBinder
                    component={Cmp}
                    index={i}
                    tools={tools}
                    props={item.props}
                    name={item.type}
                  />
                </React.Fragment>
              )
            })}
            <AddItemHandle
              onClick={() => {
                addModalActions.open(-1)
              }}
            />
          </div>
        )}
        {addModalState.value !== null && (
          <PickElementModal
            isOpen={addModalState.isOpen}
            onClosed={addModalActions.onClosed}
            onCancel={addModalActions.close}
            onConfirm={(elementType) => {
              if (addModalState.value === -1) {
                insertLast(elementType)
              } else {
                insertBefore(elementType, addModalState.value)
              }
              addModalActions.close()
            }}
          />
        )}
      </Layout.Content>
    </DefaultLayout>
  )
}

export function CMSEditorPage({ history, match }) {
  const path = match.params.path
  const [{ data: pageContent }, { update }] = useCMSFileContent(path)

  let pageData = null

  if (pageContent !== null) {
    try {
      pageData = JSON.parse(pageContent)
    } catch(err) {
      pageData = { items: [] }
    }
  }

  const pageName = last(path.split("/")).replace(".json", "")

  if (!pageData) {
    return <DefaultLayout />
  } else {
    return (
      <CMSEditorSafe
        initialState={pageData}
        pageName={pageName}
        history={history}
        onSave={(data) => update(path, data)}
      />
    )
  }
}
