import {
  faFile,
  faFolder,
  faHome,
  faUpload,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { identity } from "lodash"
import React, { useRef, useState } from "react"
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
import { CMS_BASE_URL_FOR_ASSETS } from "../../constants"
import { useCMSAssets } from "../../hooks/useCMSFiles"

export function AssetManagerModal({ isOpen, onConfirm, onCancel, onClosed }) {
  const [path, setPath] = useState("")
  const [selected, setSelected] = useState(null)
  const [{ data: assets }, { createAsset }] = useCMSAssets(path)
  const [uploading, setUploading] = useState(false)

  const pathParts = path.split("/").filter(identity)

  const [uploadName, setUploadName] = useState("")
  const [uploadFile, setUploadFile] = useState(null)
  const inputRef = useRef()

  return (
    <Modal isOpen={isOpen} toggle={onCancel} size="lg" onClosed={onClosed}>
      <ModalHeader toggle={onCancel}>Seleziona risorsa</ModalHeader>
      <ModalBody className="p-0">
        <div className="d-flex flex-row justify-content-start align-items-center p-3">
          <FontAwesomeIcon
            icon={faHome}
            className={classNames("pointer", {
              "text-primary": pathParts.length === 0,
            })}
            onClick={() => {
              setPath("")
            }}
          />
          {pathParts.map((part, i) => (
            <React.Fragment key={i}>
              <span className="mx-2">/</span>
              <span
                className={classNames("pointer", {
                  "text-primary": i === pathParts.length - 1,
                })}
                onClick={() => {
                  setPath(pathParts.slice(0, i + 1).join("/"))
                }}
              >
                {part}
              </span>
            </React.Fragment>
          ))}
        </div>
        {assets?.map((asset) => {
          if (asset.type === "directory") {
            return (
              <p
                key={asset.name}
                className="d-flex flex-row justify-content-start align-items-center border-bottom pointer m-0 px-3 py-2"
                onClick={() => {
                  setPath((path ? path + "/" : "") + asset.name)
                }}
              >
                <span className="d-inline-block" style={{ width: "2em" }}>
                  <FontAwesomeIcon icon={faFolder} />
                </span>
                <span>{asset.name}</span>
              </p>
            )
          }
          return (
            <p
              key={asset.name}
              className={classNames(
                "d-flex flex-row justify-content-start align-items-center border-bottom pointer m-0 px-3 py-2",
                {
                  "bg-primary text-white":
                    selected?.path === path && selected?.name === asset.name,
                }
              )}
              onClick={() => {
                setSelected({ path, name: asset.name })
              }}
            >
              <span className="d-inline-block" style={{ width: "2em" }}>
                <FontAwesomeIcon icon={faFile} />
              </span>
              <span>{asset.name}</span>
            </p>
          )
        })}
        {uploading && (
          <p className="d-flex flex-row justify-content-start align-items-center border-bottom pointer m-0 px-3 py-2">
            <span
              className="d-inline-block"
              style={{ width: "2em", flex: "0 0 2em" }}
            >
              <FontAwesomeIcon icon={faUpload} />
            </span>
            <i>Caricamento in corso...</i>
          </p>
        )}
        <p className="d-flex flex-row justify-content-start align-items-center border-bottom pointer m-0 px-3 py-2" key={(assets?.length ?? 0) + (uploading ? 1 : 0)}>
          <span
            className="d-inline-block"
            style={{ width: "2em", flex: "0 0 2em" }}
          >
            <FontAwesomeIcon icon={faUpload} />
          </span>
          <Input
            size="sm"
            placeholder="Nome file"
            value={uploadName}
            onChange={(e) => setUploadName(e.target.value)}
          />
          <Input
            ref={inputRef}
            size="sm"
            type="file"
            style={{ paddingTop: 1 }}
            onChange={(e) => {
              const file = e.target.files.item(0)
              setUploadFile(file)
              if (uploadName === "") {
                setUploadName(file.name)
              }
            }}
          />
          <Button
            size="sm"
            color="success"
            disabled={!uploadName || !uploadFile}
            onClick={() => {
              createAsset
                .onSuccess(() => {
                  setUploading(false)
                })
                .onFailure(() => {
                  setUploading(false)
                })
                .run((path ? path + "/" : "") + uploadName, uploadFile)
              setUploadName("")
              setUploadFile(null)
              inputRef.current.value = ""
              setUploading(true)
            }}
          >
            Carica
          </Button>
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          disabled={!selected}
          onClick={() => {
            let itemUrl = CMS_BASE_URL_FOR_ASSETS
            if (selected.path) {
              itemUrl += "/" + selected.path
            }
            itemUrl += "/" + selected.name
            onConfirm(itemUrl)
          }}
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>
  )
}
