import { rj } from "react-rocketjump"
import { API_URL } from "../constants"
import request from "superagent"

export const DivisionListRj = rj({
  effectCaller: rj.configured(),
  effect: token => () => {
    return request.get(`${API_URL}/division/`)
      .set("Authorization", `Token ${token}`)
      .then(({ body }) => body)
  }
})
