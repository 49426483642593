import magikApi from "magik-api";
import { API_URL } from "../constants";

const API = magikApi()
  .baseUrl(API_URL)
  .authHeaders((token) => ({
    Authorization: `Token ${token}`,
  }))
  .trailingSlash(true);

export default API;
