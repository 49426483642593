import React, { useState, useCallback, useMemo } from "react"
import { Modal, ModalBody } from "reactstrap"
import uniqueId from "lodash/uniqueId"
import StreamAlertContext from "./context"

const StreamAlert = ({ children }) => {
  const [stream, setStream] = useState([])
  const [isOpen, setOpen] = useState(false)

  const write = useCallback((message, kind) => {
    setStream(stream => {
      return [
        ...stream,
        { kind, message, id: 'm' + uniqueId() }
      ]
    })
    setOpen(true)
  }, [])

  const error = useCallback(message => {
    return write(message, "danger")
  }, [write])

  const warn = useCallback(message => {
    return write(message, "warning")
  }, [write])

  const success = useCallback(message => {
    return write(message, "success")
  }, [write])

  const clear = useCallback(() => {
    setStream([])
  }, [])

  const hide = useCallback(() => {
    setOpen(false)
    clear()
  }, [clear])

  const ctx = useMemo(() => ({
    error,
    warn,
    success,
    clear
  }), [clear, error, success, warn])

  return (
    <>
      <Modal isOpen={isOpen} size="sm" centered scrollable toggle={hide}>
        <ModalBody>
          <div className="d-flex flex-row justify-content-end mb-1">
            <span role="button" className="pointer" onClick={hide}>Chiudi</span>
          </div>
          {stream.map(({ kind, message, id }) => (
            <p
              key={id}
              className={`mb-1 border-${kind}`}
              style={{ borderBottom: "2px solid #ccc" }}
            >
              {message}
            </p>
          ))}

        </ModalBody>
      </Modal>
      <StreamAlertContext.Provider value={ctx}>
        {children}
      </StreamAlertContext.Provider>
    </>
  )
}

export default StreamAlert